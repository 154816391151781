import React, { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import type { ICoverPhotoBase, IFocalPointImageValues } from 'ts/common/types';
import { computeFocalPointImageValues } from 'ts/common/utils';

interface Props {
    /** The size and focal point of the image to be displayed */
    coverPhoto: ICoverPhotoBase;
    /** The recommended image url to use given the current window size */
    coverPhotoUrl: Nullable<string>;
    /** Label text for this image, for accessibility purposes. Should be provided but is optional for legacy support */
    label?: string;
}

const CoverPhoto: FC<Props> = ({ coverPhoto, coverPhotoUrl, label }) => {
    const imageRef = useRef<HTMLDivElement>(null);
    const [imageBounds, setImageBounds] = useState<IFocalPointImageValues>();
    const [isReadyToLoadImage, setIsReadyToLoadImage] = useState(false);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            // Using requestAnimationFrame prevents "Error: ResizeObserver loop completed with undelivered notifications."
            window.requestAnimationFrame(() => {
                if (imageRef.current) {
                    const nextImageBounds = computeFocalPointImageValues(
                        imageRef.current.offsetWidth,
                        imageRef.current.offsetHeight,
                        coverPhoto.width,
                        coverPhoto.height,
                        coverPhoto.focalPoint
                    );
                    setImageBounds(nextImageBounds);
                }
            });
        });

        if (imageRef.current) {
            resizeObserver.observe(imageRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [coverPhoto]);

    // Prevent loading the image until it has entered the viewport once:
    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            // Check to see if the image is within the viewport now:
            if (isIntersecting) {
                setIsReadyToLoadImage(true); // Mark the image as ready to load, since it has entered the viewport.
                intersectionObserver.disconnect(); // Disconnect the IntersectionObserver for efficiency, since we're done with it.
            }
        });

        if (imageRef.current) {
            intersectionObserver.observe(imageRef.current);
        }

        return () => {
            intersectionObserver.disconnect();
        };
    }, []);

    return (
        <Box
            ref={imageRef}
            role="img"
            aria-label={label}
            data-testid="gallery-cover-photo"
            backgroundImage={
                imageBounds && isReadyToLoadImage && coverPhotoUrl
                    ? `url(${coverPhotoUrl})`
                    : 'none'
            }
            backgroundPosition={imageBounds && `${imageBounds.offsetX}px ${imageBounds.offsetY}px`}
            backgroundSize={imageBounds && `${imageBounds.width}px ${imageBounds.height}px`}
            width="100%"
            height="100%"
        />
    );
};

export default CoverPhoto;
