import React, { FC, ReactNode, createContext, useContext } from 'react';
import api from '../hooks/api';
import { IEventCategory, IEventCategoryListResponse } from '../types';

export interface IEventCategoriesDataContext {
    categories: Nullable<IEventCategory[]>;
    loadCategories: () => Promise<IEventCategoryListResponse | null>;
}

export const EventCategoriesDataContext = createContext<IEventCategoriesDataContext>({
    categories: null,
    loadCategories: () =>
        new Promise<IEventCategoryListResponse>(() => {
            return Promise || null;
        })
});

interface IEventDataProviderProps {
    children?: ReactNode | undefined;
}

export const EventCategoriesDataProvider: FC<IEventDataProviderProps> = ({ children }) => {
    const { response: eventCategoriesResponse, performFetch: fetchEventCategories } =
        api.useClientApiFetch<IEventCategoryListResponse>('event-category', { defer: true });
    const eventCategoriesContext: IEventCategoriesDataContext = {
        categories: eventCategoriesResponse?.items || null,
        loadCategories: fetchEventCategories
    };

    return (
        <EventCategoriesDataContext.Provider value={eventCategoriesContext}>
            {children}
        </EventCategoriesDataContext.Provider>
    );
};

export const useEventCategoriesData = () => {
    return useContext(EventCategoriesDataContext);
};
