import { FetchContentType, FetchMethod } from 'ts/common/hooks';
import { usePortalApiFetch } from 'ts/portal/common/hooks';
import { useStudioApiFetch } from 'ts/studio/hooks';

interface ICreateSetupIntentResponse {
    clientSecret: string;
    info?: {
        errors?: Array<string>;
    };
    setupIntentId: string;
    requiresAction?: boolean;
    type: string;
}

interface IAddInvoiceCreditCardResponse {
    currencyCode: string;
    dueTotal: number;
}

type IUseAutoPayFetch = { invoiceId: string } & (
    | {
          brandId?: never;
          invoiceLocation: 'portal';
      }
    | {
          brandId: string;
          invoiceLocation: 'studio';
      }
);

export const useAutoPayFetch = ({ brandId, invoiceId, invoiceLocation }: IUseAutoPayFetch) => {
    const { performFetch: createPortalSetupIntent } = usePortalApiFetch<ICreateSetupIntentResponse>(
        `invoice/${invoiceId}/payment-setup-intent`,
        {
            defer: true,
            headers: {
                'Content-Type': FetchContentType.SpJson
            },
            method: FetchMethod.POST
        }
    );

    const { performFetch: addPortalInvoiceCreditCard } =
        usePortalApiFetch<IAddInvoiceCreditCardResponse>(`invoice/${invoiceId}/credit-card`, {
            defer: true,
            headers: {
                'Content-Type': FetchContentType.SpJson
            },
            method: FetchMethod.PUT
        });

    const { performFetch: createStudioSetupIntent } = useStudioApiFetch<ICreateSetupIntentResponse>(
        `brand/${brandId}/invoice/${invoiceId}/payment-setup-intent`,
        {
            defer: true,
            headers: {
                'Content-Type': FetchContentType.SpJson
            },
            method: FetchMethod.POST
        }
    );

    const { performFetch: addStudioInvoiceCreditCard } =
        useStudioApiFetch<IAddInvoiceCreditCardResponse>(
            `brand/${brandId}/invoice/${invoiceId}/credit-card`,
            {
                defer: true,
                headers: {
                    'Content-Type': FetchContentType.SpJson
                },
                method: FetchMethod.PUT
            }
        );

    return {
        addInvoiceCreditCard:
            invoiceLocation === 'portal' ? addPortalInvoiceCreditCard : addStudioInvoiceCreditCard,
        createSetupIntent:
            invoiceLocation === 'portal' ? createPortalSetupIntent : createStudioSetupIntent
    };
};
