import { spTheme } from 'sp-ui';
import { getFontFamilyPair } from 'ts/common/fonts';

const darkThemeColors = {
    primary: '#242424',
    secondary: {
        base: '#FFFFFF',
        medium: 'rgb(255 255 255 / 20%)',
        light: '#2E2E2E'
    }
};
const lightThemeColors = {
    primary: '#FFFFFF',
    secondary: {
        base: '#7F7F7F',
        medium: 'rgb(0 0 0 / 10%)',
        light: '#FAFAFA'
    }
};

export function getBrandTheme(brandThemeData) {
    const themeColors = brandThemeData.colorScheme === 'dark' ? darkThemeColors : lightThemeColors;
    const { primaryFontFamily, secondaryFontFamily } = getFontFamilyPair(brandThemeData.fontSet);
    const theme = {
        components: {
            TextInput: {
                baseStyle: {
                    borderColor: themeColors.secondary.medium,
                    ['invalid']: {
                        borderColor: spTheme.colors.red[500]
                    },
                    ['hover']: {
                        borderColor: '#a5a5a5'
                    },
                    ['focus']: {
                        borderColor: '#a5a5a5'
                    }
                }
            }
        },
        brand: {
            colors: {
                theme: themeColors,
                user: {
                    primary: `#${brandThemeData.primaryColor}`,
                    secondary: `#${brandThemeData.secondaryColor}`
                },
                common: {
                    gray: '#a5a5a5'
                },
                boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.5)',
                boxShadowOnHover: '0 1px 12px 0 rgba(0, 0, 0, 0.5)'
            },
            fonts: {
                primary: primaryFontFamily,
                secondary: secondaryFontFamily
            }
        }
    };

    return theme;
}
