import React from 'react';
import BRAND_ROUTES from 'Sp/Angular/Routes/Client/Brand';
import Route from 'Sp/Route';
import { CoverPhotoImageSize } from 'ts/common';
import { IEventCategory } from '../types';
import BrandHomepageTile from './BrandHomepageTile';

interface ICategoriesLayoutProps {
    eventCategories: IEventCategory[];
}

const CategoriesLayout: React.FC<ICategoriesLayoutProps> = ({ eventCategories }) => {
    return (
        <>
            {eventCategories.map(({ coverPhoto, id: categoryId, name }) => {
                const url = Route.get(BRAND_ROUTES.CATEGORY, { categoryId });

                return (
                    <BrandHomepageTile
                        key={`event-category-${categoryId}`}
                        coverPhoto={coverPhoto}
                        coverPhotoImageSize={CoverPhotoImageSize.Thumb}
                        linkText={name}
                        linkUrl={url}
                    />
                );
            })}
        </>
    );
};

export default CategoriesLayout;
