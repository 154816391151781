import React from 'react';
import { Flex, Heading, Radio, RadioGroup } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import { replaceHtmlEscapeCharacters } from 'ts/common/utils';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';

const PaymentOptions = ({
    paymentAmountType,
    setPaymentAmountType
}: {
    paymentAmountType: 'full' | 'retainer';
    setPaymentAmountType: (x: string) => void;
}) => {
    const t = useTranslate('booking.payment');
    const { bookingSession } = useClientBookingExperience();

    if (!bookingSession) {
        return null;
    }

    const {
        finalPaymentAmount,
        finalPaymentDueTime,
        paymentScheduleType,
        price,
        retainerPaymentAmount,
        stripeRetainerAmount
    } = bookingSession;

    if (paymentScheduleType === 'booking' || !finalPaymentDueTime) {
        return null;
    }

    return (
        <Flex flexDirection="column" gap="16px">
            <Heading as="h3" size="lg" margin={0}>
                {t('payment')}
            </Heading>
            <RadioGroup
                display="flex"
                flexDirection="column"
                gap="12px"
                onChange={setPaymentAmountType}
                value={paymentAmountType}
            >
                <Radio value="full">
                    {t('payInFull', {
                        priceTotal: price
                    })}
                </Radio>
                <Radio value="retainer">
                    {replaceHtmlEscapeCharacters(
                        t(stripeRetainerAmount === 0 ? 'payLater' : 'payRetainer', {
                            priceToday: retainerPaymentAmount,
                            priceLater: finalPaymentAmount,
                            dueDate: new Date(finalPaymentDueTime).toLocaleDateString()
                        })
                    )}
                </Radio>
            </RadioGroup>
        </Flex>
    );
};

export default PaymentOptions;
