import React from 'react';
import { ButtonGroup, ButtonGroupProps, useTheme } from '@chakra-ui/react';
import { Button, ButtonProps } from 'ts/common/components/gallery';
import { useBreakpoint } from 'ts/common/hooks';

type Props = {
    buttons: ButtonProps[];
} & ButtonGroupProps;

const ResponsiveButtonGroup = ({ buttons, ...buttonGroupProps }: Props) => {
    const theme = useTheme();
    const isLargeScreen = useBreakpoint();

    if (isLargeScreen) {
        return (
            <ButtonGroup alignSelf="center" {...buttonGroupProps}>
                {buttons.map((props) => (
                    <Button key={props.text} {...props} width="300px" />
                ))}
            </ButtonGroup>
        );
    }

    return (
        <ButtonGroup
            alignSelf="center"
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            padding="16px"
            backgroundColor="paper"
            filter={theme.filters?.dropShadow}
            {...buttonGroupProps}
        >
            {buttons.map((props) => (
                <Button key={props.text} fullWidth {...props} />
            ))}
        </ButtonGroup>
    );
};

export default ResponsiveButtonGroup;
