import React, { useCallback, useState } from 'react';
import {
    PaymentRequestPaymentMethodEvent,
    PaymentRequestShippingAddress,
    PaymentRequestShippingOption,
    PaymentRequestUpdateDetails
} from '@stripe/stripe-js';
import {
    Flex,
    PaymentRequestButton as SpUiPaymentRequestButton,
    Span,
    styled,
    useTranslate
} from 'sp-ui';

interface IPaymentRequestButtonProps {
    amount: number;
    description: string;
    onLoaded?: (hasDigitalWallet: boolean) => void;
    onPaymentMethod: (event: Omit<PaymentRequestPaymentMethodEvent, 'complete'>) => Promise<{
        errored?: boolean;
        paymentIntent?: { clientSecret: string; status: string };
    }>;
    onPaymentMethodCaptureFailed: () => void;
    onPaymentMethodCaptured: () => void;
    onShippingAddressChange?: (
        shippingAddress: PaymentRequestShippingAddress,
        updateWith: (update: PaymentRequestUpdateDetails) => void
    ) => unknown;
    onShippingOptionChange?: (
        shippingOption: PaymentRequestShippingOption,
        updateWith: (update: PaymentRequestUpdateDetails) => void
    ) => unknown;
    requestShipping?: boolean;
    shippingOptions?: { amount: number; detail: string; id: string; label: string }[];
    theme?: 'dark' | 'light' | 'light-outline';
    type?: 'book' | 'buy' | 'donate';
}

const PaymentRequestButton: React.FC<IPaymentRequestButtonProps> = ({
    amount,
    description,
    onLoaded,
    onPaymentMethodCaptureFailed,
    onPaymentMethodCaptured,
    onPaymentMethod,
    onShippingAddressChange,
    onShippingOptionChange,
    requestShipping,
    shippingOptions,
    theme,
    type
}) => {
    const [showOr, setShowOr] = useState<boolean>(false);
    const t = useTranslate();
    const toggleOrAndOnLoaded = useCallback(
        (hasDigitalWallet) => {
            setShowOr(hasDigitalWallet);

            if (onLoaded) {
                onLoaded(hasDigitalWallet);
            }
        },
        [onLoaded]
    );

    return (
        <>
            <SpUiPaymentRequestButton
                amount={amount}
                capture={{ email: true, name: true, phone: true }}
                countryCode="US"
                currency="USD"
                description={description}
                onLoaded={toggleOrAndOnLoaded}
                onPaymentMethod={onPaymentMethod}
                onPaymentMethodCaptureFailed={onPaymentMethodCaptureFailed}
                onPaymentMethodCaptured={onPaymentMethodCaptured}
                onShippingAddressChange={onShippingAddressChange}
                onShippingOptionChange={onShippingOptionChange}
                requestShipping={requestShipping}
                shippingOptions={shippingOptions}
                theme={theme}
                type={type}
            />
            {showOr && (
                <PaymentRequestButtonOrContainer alignItems="center">
                    <hr />
                    <Span margin="0 8px" textTransform="uppercase">
                        {t('or')}
                    </Span>
                    <hr />
                </PaymentRequestButtonOrContainer>
            )}
        </>
    );
};

const PaymentRequestButtonOrContainer = styled(Flex)`
    margin-bottom: 32px;
    margin-top: 32px;

    hr {
        background-color: currentColor;
        flex: 1;
        height: 1px;
        margin: 0;
    }
`;

export default PaymentRequestButton;
