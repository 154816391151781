import { useFetch } from 'ts/common/hooks/fetch';
import { useBootstrapData } from 'client_react/bootstrap';

function useClientApiFetch<T = unknown>(url = '', options = {}) {
    const { apiUrl } = useBootstrapData();
    const additionalPath = url.length > 0 ? `/${url}` : '';

    return useFetch<T>(`${apiUrl}${additionalPath}`, options);
}

export default {
    useClientApiFetch
};
