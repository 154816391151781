import React from 'react';
import { Box, H5, styled } from 'sp-ui';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';

const getUrlFromBrandLogo = (logo?: Nullable<SpApi.IBrandLogoImage>) =>
    logo ? logo.displayUrl.original : '';
const BrandLogoContainer = styled(Box)(
    ({ theme }) => `
        position: relative;
        margin: 0 auto 30px;
        text-align: center;

        img {
            display: inline;
            max-width: 300px;
            max-height: 225px;
            ${theme.responsive.mediumScreenDown`
                max-width: 220px;
                max-height: 165px;
            `}
            vertical-align: middle;
            border: 0;
        }
    `
);
const LogoHeader = styled(H5)(
    ({ theme }) => `
        padding: 10px 0 7px 0;
        text-transform: uppercase;
        color: ${theme.brand.colors.user.primary};
        font-family: "OpenSans-SemiBold", sans-serif;

        ${theme.responsive.smallScreenOnly`
            font-size: 12px;
        `}
    `
);

interface IBrandLogo {
    brandName: string;
}

const BrandLogo: React.FC<IBrandLogo> = ({ brandName }) => {
    const { brandTheme } = useBrandHomepage();
    const { eventBrandLogo } = brandTheme;
    const logoUrl = getUrlFromBrandLogo(eventBrandLogo);

    return (
        <BrandLogoContainer data-testid="brand-logo">
            {logoUrl ? (
                <img src={logoUrl} alt={brandName} data-testid="brand-logo-image" />
            ) : (
                <LogoHeader data-testid="brand-logo-header">{brandName}</LogoHeader>
            )}
        </BrandLogoContainer>
    );
};

export default BrandLogo;
