import React, { FC, PropsWithChildren } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { A, styled } from 'sp-ui';

const commonStyle = (theme) => `
    top: -3px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 18px;
    margin: 0 0 -8px 0;
    text-transform: uppercase;
    color: ${theme.brand.colors.user.secondary};
    font-family: ${theme.brand.fonts.primary};

    :hover {
        color: ${theme.brand.colors.user.secondary};
    }
`;
const AnchorLink = styled(A)(({ theme }) => commonStyle(theme));
const RouterLink = styled(ReactRouterLink)(({ theme }) => commonStyle(theme));

interface ILinkProps {
    /** Does the link lead to outside the application */
    isExternal: boolean | undefined;
    /** The URL for the link */
    url: string;
}

export const Link: FC<PropsWithChildren<ILinkProps>> = ({
    isExternal,
    url,
    children,
    ...props
}) => {
    return isExternal ? (
        <AnchorLink data-testid="tile-link-external" href={url} isExternal>
            {children}
        </AnchorLink>
    ) : (
        <RouterLink data-testid="tile-link" to={url} {...props}>
            {children}
        </RouterLink>
    );
};
