import React, { FC } from 'react';
import { Checkbox, FormControl, Link } from '@chakra-ui/react';
import type { UseFormRegister } from 'react-hook-form';

interface Props {
    register: UseFormRegister<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    translate: (key: string) => string;
}

// TODO Merge this component with the DataConsent and DataConsentLabel components.
// TODO Update this component to use a single hyperlink with an updated URL, once Layne provides it.
// TODO Use the TranslationWithComponent.tsx component to render the translated text with the hyperlink.
const DataConsentCheckbox: FC<Props> = ({ register, translate }) => {
    return (
        <FormControl
            // This is necessary to override some existing Sass styles applied globally to labels:
            sx={{ label: { margin: 0 } }}
        >
            <Checkbox
                data-testid="data-consent"
                {...register('doesAcceptTerms', {
                    validate: (value) => value === true
                })}
            >
                {translate('termsIAgree')}{' '}
                <Link
                    href="https://www.shootproof.com/legal/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {translate('termsOfUse')}
                </Link>{' '}
                {translate('termsAnd')}{' '}
                <Link
                    href="https://www.shootproof.com/legal/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {translate('privacyPolicy')}
                </Link>{' '}
                {translate('termsForSite')}
            </Checkbox>
        </FormControl>
    );
};

export default DataConsentCheckbox;
