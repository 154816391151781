import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';
import type { Theme } from '@emotion/react';
import { Elements } from '@stripe/react-stripe-js';
import { type StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import type { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';
import { ROUTE } from 'client_react/booking/common';
import BookingSessionHeading from 'client_react/booking/components/BookingSessionHeading';
import PaymentForm from 'client_react/booking/components/PaymentForm';
import PaymentOptions from 'client_react/booking/components/PaymentOptions';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { getStripeAppearance, getStripeFonts } from 'client_react/booking/helpers';
import { useBootstrapData } from 'client_react/bootstrap';

const PaymentPage = () => {
    const { bookingSession, bookingSessionType, isPending, navigateWithSession, submitPayment } =
        useClientBookingExperience();

    const [paymentAmountType, setPaymentAmountType] = useState('full');

    const t = useTranslate('booking.payment');
    const theme = useTheme() as unknown as Theme;
    const { staticUrl } = useBootstrapData();

    useEffect(() => {
        if (isPending) {
            return;
        }

        if (!bookingSession) {
            navigateWithSession(ROUTE.DATE_AND_TIME);
        } else if (!bookingSession.isIncomplete || !bookingSession.price) {
            navigateWithSession(ROUTE.CONFIRMATION);
        }
    }, [bookingSession, isPending, navigateWithSession]);

    const handleSubmitPayLater = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();
            await submitPayment({ paymentAmountType: 'retainer' });
            navigateWithSession(ROUTE.CONFIRMATION);
        },
        [navigateWithSession, submitPayment]
    );

    const payLaterButtons = useMemo<Array<ButtonProps>>(
        () => [
            {
                text: t('back'),
                variant: 'outline primary',
                onClick: () => {
                    navigateWithSession(ROUTE.CONTACT_INFORMATION);
                }
            },
            {
                text: t('bookSession'),
                isDisabled: isPending,
                variant: 'primary',
                type: 'submit'
            }
        ],
        [isPending, navigateWithSession, t]
    );

    const stripeInstance = useMemo(() => {
        if (!bookingSessionType) {
            return null;
        }

        return loadStripe(bookingSessionType.stripePublicKey, {
            apiVersion: '2023-10-16',
            stripeAccount: bookingSessionType.stripeStandardAccountId ?? undefined
        });
    }, [bookingSessionType]);

    const stripeOptions = useMemo(() => {
        if (!bookingSession) {
            return undefined;
        }

        const { stripePrice, stripeRetainerAmount } = bookingSession;

        const options: StripeElementsOptions = {
            amount: paymentAmountType === 'full' ? stripePrice : stripeRetainerAmount,
            appearance: getStripeAppearance(theme),
            currency: bookingSession.currencyCode.toLowerCase(),
            fonts: getStripeFonts(staticUrl),
            mode: 'payment',
            paymentMethodCreation: 'manual'
        };

        return options;
    }, [bookingSession, paymentAmountType, staticUrl, theme]);

    if (!bookingSession || !bookingSessionType) {
        return null;
    }

    const isPayingNow = paymentAmountType === 'full' || bookingSession.stripeRetainerAmount > 0;

    return (
        <>
            <BookingSessionHeading
                bookingSession={bookingSession}
                bookingSessionType={bookingSessionType}
            />
            {isPayingNow ? (
                <Elements options={stripeOptions} stripe={stripeInstance}>
                    <PaymentForm
                        paymentAmountType={paymentAmountType as 'full' | 'retainer'}
                        setPaymentAmountType={setPaymentAmountType}
                    />
                </Elements>
            ) : (
                <Flex as="form" flexDirection="column" gap="24px" onSubmit={handleSubmitPayLater}>
                    <PaymentOptions
                        paymentAmountType={paymentAmountType as 'full' | 'retainer'}
                        setPaymentAmountType={setPaymentAmountType}
                    />
                    <ResponsiveButtonGroup buttons={payLaterButtons} marginTop="32px" />
                </Flex>
            )}
        </>
    );
};

export default PaymentPage;
