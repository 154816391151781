import React from 'react';
import { Button, styled } from 'sp-ui';
import { useTranslate } from 'ts/common/hooks';
import { useEventsData } from '../data/eventsData';

const StyledButton = styled(Button)(
    ({ theme }) => `
        @media only screen and (max-width: 632px) {
            width: 280px;
        }
        @media only screen and (min-width: 1280px) {
            margin: 0 30px;
        }

        border: solid thin ${theme.brand.colors.user.primary};
        color: ${theme.brand.colors.user.primary};
        background-color: transparent;
        padding: 12px 19px;
        font-size: 14px;
        border-radius: 2px;
        width: 100%;
        margin: 0px 16px 16px;

        :hover {
            background-color: ${theme.brand.colors.user.primary};
            color: ${theme.brand.colors.theme.primary};
        }
    `
);

const LoadMoreButton: React.FC = () => {
    const { canLoadMoreEvents, loading, loadMoreEvents } = useEventsData();
    const t = useTranslate();

    if (!canLoadMoreEvents) {
        return null;
    }

    return (
        <StyledButton onClick={loadMoreEvents}>
            {loading ? `${t('loading')}...` : t('loadMore')}
        </StyledButton>
    );
};

export default LoadMoreButton;
