import React from 'react';
import { Box, styled } from 'sp-ui';
import { TermsAndPolicyLinks } from 'ts/common/components';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';
import BrandContactInfo from './BrandContactInfo';
import SocialNetworkLinks from './SocialNetworkLinks';

const FooterContainer = styled(Box)`
    height: 100%;
    padding: 40px 20px 0;
    bottom: 0;
    position: static;
    display: flex;
    flex-direction: column;

    p {
        font-family; 'OpenSans Regular';
        color: #a5a5a5;
    }

    .terms-and-privacy-links {
        color: #a5a5a5;
        padding-bottom: 15px;
    }
`;
const SocialContainer = styled(Box)`
    margin-bottom: 20px;
`;

const Footer: React.FC = () => {
    const { address, phone, email, facebookUrl, instagramUrl, pinterestUrl, twitterUrl, website } =
        useBrandHomepage();

    return (
        <FooterContainer as="footer" data-testid="footer-container">
            <SocialContainer data-testid="social-container">
                <SocialNetworkLinks
                    email={email}
                    facebookUrl={facebookUrl}
                    instagramUrl={instagramUrl}
                    pinterestUrl={pinterestUrl}
                    twitterUrl={twitterUrl}
                    website={website}
                />
            </SocialContainer>
            <BrandContactInfo address={address} phone={phone} />
            <TermsAndPolicyLinks />
        </FooterContainer>
    );
};

export default Footer;
