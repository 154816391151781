import React from 'react';
import { Box, styled } from 'sp-ui';
import { useTranslate } from 'ts/common/hooks';
import { PageText } from '../components/common';
import { ThemedHeading } from '../components/themed';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';

interface IAboutImageProps {
    displayUrl: IDisplayUrl;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme?: any;
}

interface IDisplayUrl {
    large: string;
    medium: string;
}

const AboutImage = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'displayUrl'
})(
    ({ displayUrl, theme }: IAboutImageProps) => `
        ${theme.responsive.mediumScreenUp(`
            background-image: url(${displayUrl.large});
        `)}

        width: 100%;
        height: 398px;
        background-image: url(${displayUrl.medium});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 42px;
    `
);

const AboutPage = () => {
    const t = useTranslate();
    const { aboutText, aboutImage } = useBrandHomepage();
    const hasAboutText = aboutText !== null;
    const hasAboutImage = aboutImage !== null;

    return (
        <>
            <ThemedHeading data-testid="brand-about-page-heading">{t('about')}</ThemedHeading>
            {hasAboutImage && (
                <AboutImage
                    data-testid="brand-about-page-about-image"
                    displayUrl={aboutImage?.displayUrl}
                />
            )}
            {hasAboutText && (
                <PageText
                    data-testid="brand-about-text"
                    dangerouslySetInnerHTML={{
                        __html: aboutText.replace(/<p>/gm, '<p style="text-align: left;">')
                    }}
                />
            )}
        </>
    );
};

export default AboutPage;
