import React from 'react';
import { Flex, styled } from 'sp-ui';
import { ReactComponent as Check } from 'svg/check.svg';

interface IButtonLoadingIndicatorProps {
    loaded: boolean;
}

const LoadingDot = styled.div<{ count: number; index: number }>(({ count, index }) => {
    const fadeDurationInSeconds = 0.225;
    const durationInSeconds = count * fadeDurationInSeconds;
    const duration = `${durationInSeconds}s`;
    const delay = `${fadeDurationInSeconds * index - durationInSeconds}s`;

    return `
        @keyframes fade-in-out {
            0% {
                opacity: 1;
            }
            33% {
                opacity: 0.5;
            }
            66% {
                opacity: 0.5;
            }
            100% {
                opacity: 1;
            }
        }

        animation: fade-in-out ${duration} linear ${delay} infinite;
        background-color: currentColor;
        border-radius: 4px;
        height: 4px;
        margin-right: 2px;
        width: 4px;

        &:last-of-type {
            margin-right: 0;
        }
    `;
});
const PulsedUnveil = styled(Flex)(({ width = '8px' }) => {
    const unveilDuration = '0.2s';

    return `
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.5);
            }
            100% {
                transform: scale(1);
            }
        }

        @keyframes unveil {
            0% {
                margin-right: ${width};
                opacity: 0.5;
                width: 0;
            }
            100% {
                margin-right: 0;
                opacity: 1;
                width: ${width};
            }
        }

        animation: pulse 0.5s ease-in-out ${unveilDuration}, unveil ${unveilDuration} linear;
        height: 24px;
        justify-content: flex-start;
        margin-bottom: -4px;
        margin-top: -4px;
        overflow: hidden;
        width: ${width};

        > * {
            min-width: ${width};
            width: ${width};
        }
    `;
});

const ButtonLoadingIndicator: React.FC<IButtonLoadingIndicatorProps> = ({ loaded }) => {
    const loadingDotCount = 3;

    return (
        <Flex marginRight="8px">
            {loaded ? (
                <PulsedUnveil>
                    <Check />
                </PulsedUnveil>
            ) : (
                Array.from(Array(loadingDotCount)).map((_, index) => (
                    <LoadingDot count={loadingDotCount} index={index} key={index} />
                ))
            )}
        </Flex>
    );
};

export default ButtonLoadingIndicator;
