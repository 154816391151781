import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from 'ts/common/hooks/fetch';

export { FetchMethod } from 'ts/common/hooks/fetch';

const BOOTSTRAP_DATA_KEY = 'SpClientBrandBootstrap';
const GLOBAL = window;

export enum Language {
    EN = 'en'
}
interface IBootstrapData {
    apiUrl: string;
    language: Language;
    staticUrl: string;
    translations: { [key: string]: string };
}

const getDefaultBootstrapData = (): IBootstrapData => ({
    apiUrl: '',
    language: Language.EN,
    staticUrl: '',
    translations: {}
});

export const getBootstrapData = (bootstrapKey = BOOTSTRAP_DATA_KEY): Promise<IBootstrapData> => {
    return Promise.resolve({
        language: Language.EN,
        ...GLOBAL[bootstrapKey]
    });
};

export const BootstrapDataContext = createContext<IBootstrapData>(getDefaultBootstrapData());

export const BootstrapDataProvider = BootstrapDataContext.Provider;

export const useBootstrapData = (): IBootstrapData => {
    return useContext<IBootstrapData>(BootstrapDataContext);
};

export const withBootstrapData = (Component: React.FC) => {
    const Wrapped = (props) => {
        const [data, setData] = useState<IBootstrapData>(getDefaultBootstrapData());
        const [isLoadingData, setIsLoadingData] = useState(true);

        useEffect(() => {
            getBootstrapData().then((data) => {
                setData(data);
                setIsLoadingData(false);
            });
        }, []);

        if (isLoadingData) {
            return null;
        }

        return (
            <BootstrapDataProvider value={data}>
                <Component {...props} />
            </BootstrapDataProvider>
        );
    };

    return Wrapped;
};

export function useClientApiFetch<T = unknown>(url = '', options = {}) {
    const { apiUrl } = useBootstrapData();
    const additionalPath = url.length > 0 ? `/${url}` : '';

    return useFetch<T>(`${apiUrl}${additionalPath}`, options);
}
