import React, { RefObject, forwardRef, useContext } from 'react';
import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Flex, Input } from '@chakra-ui/react';
import { DatePickerContext } from './DatePicker';

const DatePickerInput = forwardRef(function DatePickerInput(props, ref) {
    const calendarIcon = <CalendarIcon height="24px" width="24px" />;

    const { isInvalid, open } = useContext(DatePickerContext);

    return (
        <Flex ref={ref as RefObject<HTMLDivElement>} role="group">
            <Input isInvalid={isInvalid} isReadOnly={true} {...props} />
            <Box onClick={open} cursor="pointer" padding="8px" marginLeft={undefined}>
                {calendarIcon}
            </Box>
        </Flex>
    );
});

export default DatePickerInput;
