import React, { useEffect, useMemo } from 'react';
import { Flex, FormControl, FormLabel, Grid, Heading, Input, useToast } from '@chakra-ui/react';
import { type UseFormRegister, useForm } from 'react-hook-form';
import DataConsentCheckbox from 'ts/common/components/DataConsentCheckbox';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';
import { ROUTE } from 'client_react/booking/common';
import BookingSessionHeading from 'client_react/booking/components/BookingSessionHeading';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { isBookingSessionError } from 'client_react/booking/useBookingSession';

interface ContactFieldValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

const ContactFormField = ({
    fieldName,
    inputType,
    register
}: {
    fieldName: keyof ContactFieldValues;
    inputType?: 'email' | 'tel' | 'text';
    register: UseFormRegister<ContactFieldValues>;
}) => {
    const t = useTranslate('booking.contactInformationPage');

    return (
        <FormControl isRequired maxWidth="calc(100vw - 32px)" padding={0}>
            <FormLabel>{t(fieldName)}</FormLabel>
            <Input
                boxSizing="border-box"
                width="100%"
                type={inputType}
                {...register(fieldName, {
                    required: true
                })}
            />
        </FormControl>
    );
};

const ContactInformationPage = () => {
    const {
        formState: { isSubmitting, isValid },
        handleSubmit,
        register
    } = useForm<ContactFieldValues>({ mode: 'onTouched' });

    const {
        bookingSession,
        bookingSessionType,
        isPending,
        updateBookingSession,
        submitPayment,
        navigateWithSession
    } = useClientBookingExperience();

    const t = useTranslate('booking.contactInformationPage');

    const errorToast = useToast({
        status: 'error'
    });

    const handleSubmitForm = handleSubmit(async (contactFieldValues: ContactFieldValues) => {
        const response = await updateBookingSession({
            sessionTypePublicId: bookingSessionType?.publicId,
            contact: {
                type: 'contact',
                ...contactFieldValues
            }
        });

        if (isBookingSessionError(response)) {
            errorToast({
                title: t('apiError')
            });
        } else if (bookingSession?.stripePrice === 0) {
            await submitPayment({ paymentAmountType: 'full' });
            navigateWithSession(ROUTE.CONFIRMATION);
        } else {
            navigateWithSession(ROUTE.PAYMENT);
        }
    });

    useEffect(() => {
        if (isPending) {
            return;
        }

        if (!bookingSession) {
            navigateWithSession(ROUTE.DATE_AND_TIME);
        } else if (!bookingSession.isIncomplete) {
            navigateWithSession(ROUTE.CONFIRMATION);
        }
    }, [bookingSession, isPending, navigateWithSession]);

    const buttons = useMemo<ButtonProps[]>(
        () => [
            {
                text: t('back'),
                onClick: () => navigateWithSession(ROUTE.DATE_AND_TIME),
                variant: 'outline primary'
            },
            {
                text: t('continue'),
                isDisabled: isPending || isSubmitting || !isValid,
                isLoading: isSubmitting,
                variant: 'primary',
                type: 'submit'
            }
        ],
        [isPending, isSubmitting, isValid, navigateWithSession, t]
    );

    if (!bookingSession || !bookingSessionType) {
        return null;
    }

    return (
        <>
            <BookingSessionHeading
                bookingSession={bookingSession}
                bookingSessionType={bookingSessionType}
            />
            <Flex as="form" direction="column" gap="24px" onSubmit={handleSubmitForm}>
                <Heading as="h3" size="lg" margin={0}>
                    {t('heading')}
                </Heading>
                <Grid gap="16px 24px" gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))">
                    <ContactFormField fieldName="firstName" register={register} />
                    <ContactFormField fieldName="lastName" register={register} />
                    <ContactFormField fieldName="email" inputType="email" register={register} />
                    <ContactFormField fieldName="phone" inputType="tel" register={register} />
                </Grid>
                {bookingSession.isSubjectToGdpr && (
                    <DataConsentCheckbox register={register} translate={t} />
                )}
                <ResponsiveButtonGroup buttons={buttons} />
            </Flex>
        </>
    );
};

export default ContactInformationPage;
