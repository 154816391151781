const TYPE = {
    CANVAS: 'canvas',
    CARDS: 'cards',
    CUSTOM: 'custom',
    DIGITALS: 'digitals',
    FINE_ART: 'fine-art',
    METALS: 'metals',
    ORNAMENTS: 'ornaments',
    PACKAGES: 'packages',
    PRINTS: 'prints',
    PRODUCTS: 'products',
    ACRYLIC_BLOCKS: 'acrylic-blocks',
    FRAMED_PRINTS: 'framed-prints',
    MATTED_FRAMED_PRINTS: 'matted-framed-prints'
};

const TRANSLATION_KEY = {
    [TYPE.ACRYLIC_BLOCKS]: 'studio.priceSheet.groupType.acrylicBlock',
    [TYPE.CANVAS]: 'studio.priceSheet.groupType.canvas',
    [TYPE.CARDS]: 'studio.priceSheet.groupType.card',
    [TYPE.CUSTOM]: 'studio.priceSheet.groupType.custom',
    [TYPE.DIGITALS]: 'studio.priceSheet.groupType.digital',
    [TYPE.FINE_ART]: 'studio.priceSheet.groupType.fineArt',
    [TYPE.FRAMED_PRINTS]: 'studio.priceSheet.groupType.framedPrint',
    [TYPE.METALS]: 'studio.priceSheet.groupType.metal',
    [TYPE.ORNAMENTS]: 'studio.priceSheet.groupType.ornament',
    [TYPE.PACKAGES]: 'studio.priceSheet.groupType.package',
    [TYPE.PRINTS]: 'studio.priceSheet.groupType.print',
    [TYPE.PRODUCTS]: 'studio.priceSheet.groupType.product',
    [TYPE.MATTED_FRAMED_PRINTS]: 'studio.priceSheet.groupType.mattedFramedPrint'
};

const LEGACY_CATEGORY_SET = new Set([TYPE.CANVAS, TYPE.PRINTS, TYPE.FINE_ART, TYPE.METALS]);

export default {
    TYPE,
    TRANSLATION_KEY,
    LEGACY_CATEGORY_SET
};
