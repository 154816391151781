import React, { useEffect } from 'react';
import { ROUTE } from 'client_react/booking/common';
import { useClientBookingExperience } from './ClientBookingExperience';
import ConfirmationContent from './components/ConfirmationContent';

const ConfirmationPage = () => {
    const { bookingSession, bookingSessionType, isPending, navigateWithSession } =
        useClientBookingExperience();

    useEffect(() => {
        if (isPending) {
            return;
        }

        if (!bookingSession) {
            navigateWithSession(ROUTE.DATE_AND_TIME);
        } else if (bookingSession.isIncomplete) {
            navigateWithSession(ROUTE.CONTACT_INFORMATION);
        }
    }, [bookingSession, isPending, navigateWithSession]);

    if (!bookingSession && !bookingSessionType) {
        return null;
    }

    return (
        <ConfirmationContent
            bookingSession={bookingSession}
            bookingSessionType={bookingSessionType}
            showSubtitle
        />
    );
};

export default ConfirmationPage;
