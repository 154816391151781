import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, styled } from 'sp-ui';

const Container = styled(Box)`
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 1px;
    perspective-origin: right top;
    position: sticky;
    scrollbar-width: none;
    transform-style: preserve-3d;
    width: 100%;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const Content = styled(Box)`
    transform-style: preserve-3d;
    position: absolute;
    width: 100%;
`;

const ScrollableBox: React.FC<BoxProps> = (props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isDraggingScrollbar, setIsDraggingScrollbar] = useState(false);
    const [isHoveredOver, setIsHoveredOver] = useState(false);
    const [isScrollbarHoveredOver, setIsScrollbarHoveredOver] = useState(false);
    const isMouseOverScrollbar = useCallback(({ pageX, pageY }: React.MouseEvent) => {
        const { current: scrollbar } = scrollbarRef;

        if (scrollbar) {
            const { height, left, top, width } = scrollbar.getBoundingClientRect();
            const isOverScrollbar =
                pageX >= left && pageX <= left + width && pageY >= top && pageY <= top + height;

            return isOverScrollbar;
        }

        return false;
    }, []);
    const onMouseMove = useCallback(({ pageY: position }) => {
        const { current: container } = containerRef;

        if (container) {
            const { clientHeight, scrollHeight } = container;
            const maxScrollTop = scrollHeight - clientHeight;
            const { top } = container.getBoundingClientRect();
            const nextScrollTop = position - top;

            container.scrollTop = nextScrollTop > maxScrollTop ? maxScrollTop : nextScrollTop;
        }
    }, []);
    const renderScrollbar = useCallback(() => {
        const { current: scrollbar } = scrollbarRef;

        if (scrollbar) {
            scrollbar.style.height = '0px';
            scrollbar.style.transform = '';
        }

        const { current: container } = containerRef;

        if (container) {
            const { clientHeight, scrollHeight: containerScrollHeight } = container;

            if (containerScrollHeight > clientHeight) {
                // Adapted from [CSS Deep-Dive - matrix3d() for a frame-perfect custom scrollbar](https://developer.chrome.com/blog/custom-scrollbar/)
                const { height: containerHeight } = container.getBoundingClientRect();
                const scrollbarHeight = (containerHeight * containerHeight) / containerScrollHeight;
                const scrollbarVerticalMargin = 8;
                const scrollbarScalingFactor =
                    (containerHeight - scrollbarHeight) / (containerScrollHeight - containerHeight);
                const scale = 1 / scrollbarScalingFactor;

                if (scrollbar) {
                    scrollbar.style.height = `${scrollbarHeight - scrollbarVerticalMargin}px`;
                    scrollbar.style.transform = `
                        matrix3d(
                            1, 0, 0, 0,
                            0, 1, 0, 0,
                            0, 0, 1, 0,
                            0, 0, 0, -1
                        )
                        scale(${scale})
                        translateZ(${1 - scale - 2}px)
                    `;
                }
            }
        }
    }, []);
    const scrollbarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const { current: content } = contentRef;

        const onMouseUp = () => {
            setIsDraggingScrollbar(false);

            document.removeEventListener('mousemove', onMouseMove);
        };

        let resizeObserver: ResizeObserver;

        document.addEventListener('mouseup', onMouseUp);
        window.addEventListener('resize', renderScrollbar);

        if (window.ResizeObserver && content) {
            resizeObserver = new ResizeObserver(renderScrollbar);

            resizeObserver.observe(content);
        }

        return () => {
            document.removeEventListener('mouseup', onMouseUp);
            window.removeEventListener('resize', renderScrollbar);

            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [onMouseMove, renderScrollbar]);

    return (
        <Container ref={containerRef}>
            <Content
                onMouseDown={(event) => {
                    if (isMouseOverScrollbar(event)) {
                        document.addEventListener('mousemove', onMouseMove);
                        event.preventDefault();
                        setIsDraggingScrollbar(true);
                    }
                }}
                onMouseEnter={() => setIsHoveredOver(true)}
                onMouseLeave={() => setIsHoveredOver(false)}
                onMouseMove={(event) => {
                    setIsScrollbarHoveredOver(isMouseOverScrollbar(event));
                }}
                ref={contentRef}
            >
                <Scrollbar
                    isDragging={isDraggingScrollbar}
                    isHoveredOver={isScrollbarHoveredOver}
                    ref={scrollbarRef}
                    show={isDraggingScrollbar || isHoveredOver}
                />
                <Box {...props} />
            </Content>
        </Container>
    );
};

const Scrollbar = styled(Box, {
    shouldForwardProp: (prop) => !['isDragging', 'isHoveredOver', 'show'].includes(prop)
})<{
    isDragging: boolean;
    isHoveredOver: boolean;
    show: boolean;
}>(
    ({ isDragging, isHoveredOver, show, theme }) => `
        background: ${theme.colors.gray[500]};
        border-radius: 4px;
        opacity: ${show ? (isDragging || isHoveredOver ? 1 : 0.8) : 0};
        position: absolute;
        right: -4px;
        top: -4px;
        transform-origin: right top;
        transition: opacity, right, width 0.275s ease-in-out;
        width: ${isDragging || isHoveredOver ? 6 : 4}px;
    `
);

export default ScrollableBox;
