import React from 'react';
import { A, P, styled } from 'sp-ui';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';

interface IBrandContactInfoProps {
    address: Nullable<string>;
    phone: Nullable<string>;
}

const PhoneLink = styled(A)(() => {
    const { brandTheme } = useBrandHomepage();

    return `
        color: #${brandTheme.secondaryColor};
    `;
});

const BrandContactInfo: React.FC<IBrandContactInfoProps> = ({ address, phone }) => {
    const hasAddress = address !== null;
    const hasPhone = phone !== null;

    if (!hasAddress && !hasPhone) {
        return null;
    }

    return (
        <P data-testid="brand-contact-info-container" textAlign="center">
            {hasAddress && (
                <span
                    data-testid="brand-contact-info-address"
                    dangerouslySetInnerHTML={{
                        __html: address
                    }}
                />
            )}
            {hasAddress && hasPhone && <br />}
            {hasPhone && (
                <PhoneLink data-testid="brand-contact-info-phone" href={`tel:${phone}`}>
                    {phone}
                </PhoneLink>
            )}
        </P>
    );
};

export default BrandContactInfo;
