import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@chakra-ui/react';

/**
 * Returns true only if we should be rendering for the given breakpoint or larger.
 * Uses 'lg' by default, if no argument is passed. Updates when the window resizes.
 */
export const useBreakpoint = (breakpoint: 'md' | 'lg' | 'xl' = 'lg') => {
    // Since we're using an outdated version of Chakra, we can't use useBreakpointValue's { ssr: false } option,
    // to prevent Chakra from rendering initially with the base breakpoint. This block of code produces the same effect:
    const theme = useTheme();

    // Override the theme's breakpoints to avoid updating sp-ui's breakpoints, until we remove it as a dependency:
    const breakpoints = useMemo(
        () => ({
            ...theme.breakpoints,
            xl: '1499px'
        }),
        [theme]
    );

    const getResult = useCallback(() => {
        const mediaQuery = `only screen and (min-width: calc(${breakpoints[breakpoint]} + 1px))`;
        const returnValue = matchMedia(mediaQuery).matches;

        return returnValue;
    }, [breakpoint, breakpoints]);

    const [result, setResult] = useState(getResult);

    useEffect(() => {
        const resetLargeScreen = () => {
            setResult(getResult());
        };

        window.addEventListener('resize', resetLargeScreen);

        return () => {
            window.removeEventListener('resize', resetLargeScreen);
        };
    }, [getResult]);

    return result;
};
