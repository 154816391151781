enum FontFamilyPairSlug {
    Brandon = 'brandon',
    Freeland = 'freeland',
    Baskerville = 'baskerville',
    Blooming = 'blooming-elegant',
    BambusaPro = 'bambusa-pro-regular',
    SBOne = 'scandiebox-one'
}

const fontFamilyPairs = {
    [FontFamilyPairSlug.Brandon]: {
        primaryFontFamilies: ['Brandon', 'Arial', 'Helvetica', 'sans-serif'],
        secondaryFontFamilies: ['Brandon', 'Arial', 'Helvetica', 'sans-serif']
    },
    [FontFamilyPairSlug.Freeland]: {
        primaryFontFamilies: ['ProximaNova', 'ProximaNova-Regular', 'Arial', 'sans-serif'],
        secondaryFontFamilies: ['Freeland', 'Arial Bold', 'sans-serif']
    },
    [FontFamilyPairSlug.Baskerville]: {
        primaryFontFamilies: [
            'Baskerville',
            'Baskerville Old Face',
            'Goudy Old Style',
            'Garamond',
            'Times New Roman',
            'Serif'
        ],
        secondaryFontFamilies: ['open-sans', 'Tahoma', 'sans-serif']
    },
    [FontFamilyPairSlug.Blooming]: {
        primaryFontFamilies: ['open-sans', 'Tahoma', 'sans-serif'],
        secondaryFontFamilies: ['blooming-elegant', 'Times New Roman', 'Serif']
    },
    [FontFamilyPairSlug.BambusaPro]: {
        primaryFontFamilies: ['open-sans', 'Tahoma', 'sans-serif'],
        secondaryFontFamilies: ['bambusa-pro-regular', 'Times New Roman', 'Serif']
    },
    [FontFamilyPairSlug.SBOne]: {
        primaryFontFamilies: ['open-sans', 'Arial Bold', 'sans-serif'],
        secondaryFontFamilies: ['scandiebox-one', 'Times New Roman', 'serif']
    }
};

function joinFontFamilies(fontFamilies: string[]) {
    return fontFamilies.join(', ');
}

/**
 * A ShootProof-curated pair of font families for gallery theming
 */
export interface IFontFamilyPair {
    primaryFontFamily: string;
    secondaryFontFamily: string;
}

/**
 * Choose a FontFamilyPair via its font set slug
 */
export function getFontFamilyPair(fontSlug: string): IFontFamilyPair {
    const primaryFontFamily = joinFontFamilies(fontFamilyPairs[fontSlug].primaryFontFamilies);
    const secondaryFontFamily = joinFontFamilies(fontFamilyPairs[fontSlug].secondaryFontFamilies);

    return { primaryFontFamily, secondaryFontFamily };
}

export const isCursiveFont = (font: SpApi.FontSet) =>
    ['bambusa-pro-regular', 'blooming-elegant', 'freeland', 'scandiebox-one'].includes(font);
