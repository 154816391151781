import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClientBookingData } from 'client_react/booking/App';
import ConfirmationContent from './components/ConfirmationContent';

const SessionPage = () => {
    const { bookingSession, isPending } = useClientBookingData();

    const navigate = useNavigate();

    useEffect(() => {
        if (!isPending && !bookingSession) {
            navigate('../not-found');
        }
    }, [bookingSession, isPending, navigate]);

    return (
        <ConfirmationContent
            bookingSession={bookingSession}
            bookingSessionType={null}
            showSubtitle={false}
        />
    );
};

export default SessionPage;
