import React, { FC } from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';

interface Props {
    /** True if the user is in Compare mode */
    isComparing?: boolean;
    /** True if the Package Builder is open */
    isPackageBuilderOpen?: boolean;
    /** True if the component is being rendered in Portal */
    isPortal?: boolean;
}

const TermsAndPolicyLinks: FC<Props> = ({ isComparing, isPackageBuilderOpen, isPortal }) => {
    const t = useTranslate();

    if (isComparing || isPackageBuilderOpen) {
        return null;
    }

    return (
        <Flex
            height="100%"
            width="100%"
            alignItems={isPortal ? 'center' : 'flex-end'}
            justifyContent="center"
            marginBottom={{ base: '0', lg: '0' }}
            paddingTop="15px"
            paddingBottom={isPortal ? { base: '15px', lg: '15px' } : { base: '125px', lg: '15px' }}
            className="terms-and-privacy-links"
        >
            <Link
                href="https://www.shootproof.com/legal/terms-of-use/"
                target="_blank"
                fontSize="xs"
                color="currentColor"
                _focus={{
                    boxShadow: 'none'
                }}
                _focusVisible={{
                    boxShadow: 'outline'
                }}
                _visited={{
                    color: 'currentColor'
                }}
            >
                {t('termsOfUse')}
            </Link>
            <Box width="20px" textAlign="center">
                |
            </Box>
            <Link
                href="https://www.shootproof.com/legal/privacy-policy/"
                target="_blank"
                fontSize="xs"
                color="currentColor"
                _focus={{
                    boxShadow: 'none'
                }}
                _focusVisible={{
                    boxShadow: 'outline'
                }}
                _visited={{
                    color: 'currentColor'
                }}
            >
                {t('privacyPolicy')}
            </Link>
        </Flex>
    );
};

export default TermsAndPolicyLinks;
