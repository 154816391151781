import React, { FC, PropsWithChildren, RefObject, createContext, useRef } from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import ReactDatePicker from 'react-datepicker';
import { noop } from 'ts/common/utils';
import DatePickerHeaderControls, {
    IDatePickerHeaderControlsProps
} from './DatePickerHeaderControls';
import DatePickerInput from './DatePickerInput';

interface IDatePickerProps {
    dateFormat?: string;
    id?: string;
    inline?: boolean;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isValid?: boolean;
    max?: Date;
    min?: Date;
    name?: string;
    onChange: (value: Date | null) => void;
    placeholder?: string;
    value: Date | null;
    centeredDate?: boolean;
    availabilities?: {
        [date: string]: string[];
    };
    disableUnavailableDays?: boolean;
    disabledDates?: string[];
}

interface IDatePickerContext {
    close: () => void;
    dateFormat: string;
    inline?: boolean;
    isInvalid?: boolean;
    isValid?: boolean;
    max?: Date;
    min?: Date;
    open: () => void;
    onChange: (value: Date | null) => void;
    value: Date | null;
    centeredDate: boolean;
}

interface ICustomDatePickerTheme {
    components: {
        DatePicker: {
            baseStyle: {
                fontFamily?: string;
                backgroundColor?: string;
                headerBackgroundColor?: string;
                color?: string;
                dateColor?: string;
                disabledColor?: string;
            };
        };
    };
}

// TODO: See about fixing it another way.
const GlobalStyles = () => (
    <Global
        styles={css`
            .react-datepicker-popper {
                background-color: white;
                z-index: 100;
                border: 1px solid gray;
                border-radius: 4px;
            }
        `}
    />
);

const formatDateToString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const DatePickerCalendarContainer = styled(Box)(({ theme }) => {
    const customTheme = theme as ICustomDatePickerTheme;
    const { DatePicker } = customTheme.components;
    const { fontFamily, backgroundColor, headerBackgroundColor, color, dateColor, disabledColor } =
        DatePicker.baseStyle;

    return `
        font-family: ${fontFamily};

        .react-datepicker__month-container {
            width: 100%;
        }

        .react-datepicker__header.react-datepicker__header--custom {
            background-color: ${headerBackgroundColor};
            border-bottom: 0px;
        }

        .react-datepicker__day {
            font-family: ${fontFamily};
            color: ${color};
            width: 42px;
            line-height: 42px;

            &:hover {
                background: none;
            }

            @media (max-width: 768px) {
                width: 32px;
                line-height: 32px;
            }

            @media (max-width: 480px) {
                width: 32px;
                line-height: 32px;
            }
        }

        .react-datepicker__day-names {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 8px 0px 8px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            line-height: 12px; /* 100% */
            letter-spacing: 0.36px;
            text-transform: uppercase;

            .react-datepicker__day-name {
                color: ${color};
                width: 40px;
                padding: 2px 10px 2px 10px;

                @media (max-width: 768px) {
                    width: 30px;
                    padding: 2px 5px;
                }

                @media (max-width: 480px) {
                    width: 28px;
                    padding: 2px;
                }
            }
        }

        .react-datepicker__week {
            display: flex;
            padding: var(--border-radius-400, 4px) 0px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            gap: 10px;
            padding-left: 10px;
            padding-right: 10px;

            @media (max-width: 768px) {
                gap: 5px;
                padding-left: 5px;
                padding-right: 5px;
            }

            @media (max-width: 480px) {
                gap: 2px;
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        .react-datepicker__month {
            margin: 0px;
        }

        .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
            background-color: ${backgroundColor};
            border-radius: 33px;
            color: ${dateColor};

            &:hover {
                background: ${backgroundColor};
            }
        }

        .react-datepicker__day--disabled {
            color: ${disabledColor};
            cursor: default;
            background-color: transparent;

            &:hover {
                background: transparent;
            }
        }
    `;
});

const DatePickerCalendar: FC<PropsWithChildren> = ({ children }) => {
    return <DatePickerCalendarContainer>{children}</DatePickerCalendarContainer>;
};

const DatePickerHeader = (props: IDatePickerHeaderControlsProps) => (
    <DatePickerHeaderControls {...props} />
);

const DatePicker: FC<IDatePickerProps> = ({
    dateFormat = 'L',
    id,
    isDisabled,
    isInvalid,
    isValid,
    max,
    min,
    onChange = noop,
    placeholder,
    value,
    centeredDate = false,
    availabilities = [],
    disableUnavailableDays = false,
    disabledDates = [],
    inline,
    ...props
}) => {
    const datePickerRef = useRef<ReactDatePicker>();
    const {
        components: {
            DatePicker: {
                baseStyle: { color: themeColor }
            }
        }
    } = useTheme<ICustomDatePickerTheme>();

    const renderDayContents = (day: number, date: Date) => {
        const formattedDate = formatDateToString(date);
        const availability = availabilities[formattedDate];

        const hasTimeSlots = availability && availability.length > 0;
        const isSelected = value && formatDateToString(value) === formattedDate;
        const isWithinRange = (!min || date >= min) && (!max || date <= max);
        const isUnavailable = disableUnavailableDays && !hasTimeSlots && isWithinRange;
        const color = hasTimeSlots && !isSelected ? '#141617' : isUnavailable ? themeColor : '';

        return (
            <Box
                gap="10px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                borderRadius="50%"
                backgroundColor={hasTimeSlots && !isSelected ? '#EDF1F3' : 'transparent'}
                color={color}
            >
                {day}
            </Box>
        );
    };

    const filterDate = (date: Date) => {
        const formattedDate = formatDateToString(date); // Format date to 'YYYY-MM-DD'

        // Check if the formatted date string is in the disabledDates array
        const isDateDisabled = disabledDates.includes(formattedDate);

        // Check availability if disableUnavailableDays is enabled
        const availability = availabilities[formattedDate];
        const isAvailable = availability && availability.length > 0;

        if (disableUnavailableDays) {
            return isAvailable && !isDateDisabled;
        }

        // Disable only based on disabledDates if disableUnavailableDays is false
        return !isDateDisabled;
    };

    return (
        <>
            <GlobalStyles />
            <DatePickerContext.Provider
                value={{
                    close: () => {
                        if (datePickerRef && datePickerRef.current) {
                            datePickerRef.current.setOpen(false);
                        }
                    },
                    dateFormat,
                    inline,
                    isInvalid,
                    isValid,
                    max,
                    min,
                    open: () => {
                        if (datePickerRef && datePickerRef.current) {
                            datePickerRef.current.setOpen(true);
                        }
                    },
                    onChange,
                    value,
                    centeredDate
                }}
            >
                <ReactDatePicker
                    calendarContainer={DatePickerCalendar}
                    customInput={<DatePickerInput />}
                    disabled={isDisabled}
                    id={id}
                    maxDate={max}
                    minDate={min}
                    onChange={onChange}
                    placeholderText={placeholder}
                    popperPlacement="bottom-start"
                    ref={datePickerRef as RefObject<ReactDatePicker>}
                    renderCustomHeader={DatePickerHeader}
                    renderDayContents={renderDayContents}
                    selected={value}
                    inline={inline}
                    shouldCloseOnSelect={true}
                    useWeekdaysShort
                    filterDate={filterDate}
                    {...props}
                />
            </DatePickerContext.Provider>
        </>
    );
};

export const DatePickerContext = createContext<IDatePickerContext>({
    close: noop,
    dateFormat: 'L',
    inline: false,
    isInvalid: false,
    isValid: false,
    max: undefined,
    min: undefined,
    open: noop,
    onChange: noop,
    value: null,
    centeredDate: false
});

export default DatePicker;
