import { IBookingSessionAvailability } from './booking/types';

export const getFirstAvailableDate = (availability: IBookingSessionAvailability) => {
    const dates = Object.keys(availability);

    for (const date of dates) {
        if (availability[date].length > 0) {
            return date;
        }
    }

    return null;
};

export const setDateToUTC = (dateString: string) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(Date.UTC(year, month - 1, day, 12, 0, 0)); // We get our date strings in UTC from the backend
};
