import React, { type PropsWithChildren } from 'react';
import { Box, Flex, Heading, useTheme } from '@chakra-ui/react';
import { useBreakpoint, useTranslate } from 'ts/common/hooks';
import BookingMarquee from 'client_react/booking/components/BookingMarquee';
import type { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

interface Props {
    bookingSession?: Nullable<IBookingSession>;
    bookingSessionType?: Nullable<IBookingSessionType>;
}

const PageContainer = ({
    bookingSession,
    bookingSessionType,
    children
}: PropsWithChildren<Props>) => {
    const isLargeScreen = useBreakpoint();
    const theme = useTheme();
    const t = useTranslate('booking');

    const brandName = bookingSession?.brandName ?? bookingSessionType?.brandName ?? '';
    const images = bookingSession?.images ?? bookingSessionType?.images;

    return (
        <Box
            bg={{ base: 'paper', lg: 'paperBackground' }}
            color="var(--chakra-colors-text)"
            height="100%"
            width="100%"
        >
            <Flex
                width="full"
                height={{ base: '50px', lg: '80px' }}
                backgroundColor="paper"
                alignItems="center"
            >
                <Heading as="h2" size="sm" color="brand.primary" margin={0} marginLeft="16px">
                    {brandName}
                </Heading>
            </Flex>
            <Flex
                width="100%"
                maxWidth={!images || images.length === 0 ? undefined : '1440px'}
                backgroundColor={!images || images.length === 0 ? 'brand.primary' : undefined}
                height={isLargeScreen ? '300px' : '200px'}
                marginX="auto"
                alignItems="center"
                justifyContent="center"
                overflow="hidden"
            >
                {!images || images.length === 0 ? (
                    <Heading
                        as="h1"
                        color="background"
                        letterSpacing={isLargeScreen ? '6px' : '3px'}
                        margin="0 8px"
                        position="relative"
                        size={isLargeScreen ? '3xl' : '2xl'}
                        textAlign="center"
                        textShadow="0px 5px 9px rgba(0, 0, 0, 0.25)"
                        top={isLargeScreen ? '-26px' : undefined}
                    >
                        {!bookingSession || bookingSession.isIncomplete
                            ? bookingSessionType?.name ?? bookingSession?.bookingSessionTypeName
                            : t('confirmationPage.title')}
                    </Heading>
                ) : images.length === 1 ? (
                    <Box
                        backgroundImage={`url(${images[0].url})`}
                        backgroundPosition="center"
                        backgroundSize="cover"
                        height="100%"
                        width="100%"
                    />
                ) : (
                    <BookingMarquee images={images} />
                )}
            </Flex>
            <Box
                background="paper"
                borderRadius="20px"
                boxSizing="border-box"
                fontFamily={theme.fonts}
                position="relative"
                boxShadow={isLargeScreen ? '0px 4px 40px 0px rgba(0, 0, 0, 0.08)' : undefined}
                marginX={isLargeScreen ? 'auto' : undefined}
                maxWidth={isLargeScreen ? '960px' : undefined}
                padding={isLargeScreen ? '40px 34px' : '16px 16px 96px 16px'}
                top={isLargeScreen ? '-52px' : undefined}
            >
                {children}
            </Box>
        </Box>
    );
};

export default PageContainer;
