import React from 'react';
import BRAND_ROUTES from 'Sp/Angular/Routes/Client/Brand';
import Route from 'Sp/Route';
import { CoverPhotoImageSize } from 'ts/common';
import { IEvent } from '../types';
import BrandHomepageTile from './BrandHomepageTile';

interface IEventsLayoutProps {
    events: IEvent[];
}

const EventsLayout: React.FC<IEventsLayoutProps> = ({ events }) => {
    return (
        <>
            {events.map(({ coverPhoto, id, name }) => {
                const url = Route.get(BRAND_ROUTES.EVENT, { galleryId: id });

                return (
                    <BrandHomepageTile
                        key={`event-${id}`}
                        coverPhoto={coverPhoto}
                        coverPhotoImageSize={CoverPhotoImageSize.Large}
                        linkText={name}
                        linkUrl={url}
                        useExternalLink={true}
                    />
                );
            })}
        </>
    );
};

export default EventsLayout;
