import React from 'react';
import { BoxProps, Flex, useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled';

export enum BannerVariant {
    Danger = 'danger',
    Neutral = 'neutral',
    Warning = 'warning'
}

export type IBannerProps = {
    /**
     * Uses a smaller sized version of the banner with less padding
     */
    smallSize?: boolean;
    /**
     * Color scheme variants of the banner, defaults to Neutral
     */
    variant?: BannerVariant;
} & BoxProps;

interface IBannerContainerProp {
    smallSize?: boolean;
    /**
     * Color scheme variants of the banner, defaults to Neutral
     */
    variant: BannerVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme?: any;
}

/**
 * A spiffy banner with multiple color variants. Defaults to Neutral.
 */
const Banner: React.FC<IBannerProps> = ({
    smallSize,
    variant = BannerVariant.Neutral,
    ...props
}) => {
    const { components } = useTheme();
    const { Banner } = components;
    const { [variant]: bannerVariant } = Banner.variants;
    const { backgroundColor, border } = bannerVariant;

    return (
        <BannerContainer
            backgroundColor={backgroundColor}
            smallSize={smallSize}
            border={border}
            data-testid="sp-ui-banner"
            variant={variant}
            {...props}
        />
    );
};

export const BannerContainer = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'smallSize'
})<{ variant: BannerVariant }>(
    ({ theme, smallSize, variant }: IBannerContainerProp) => `
        align-items: center;
        border-left-width: 4px;
        border-radius: 4px;
        padding:  ${smallSize ? '0 20px 0 16px' : '18px 20px 18px 16px'};

        p {
            color: ${theme.colors.gray[600]};
            margin-bottom: 0;
        }

        svg {
            color: ${theme.components.Banner.variants[variant].svg.color};
        }
    `
);

export default Banner;
