import React from 'react';
import {
    Box,
    ButtonGroup,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useTranslate } from 'ts/common';
import { Button } from 'ts/common/components/gallery';

export const ExpiredBookingSessionModal: React.FC<{
    expired: boolean;
}> = ({ expired }) => {
    const t = useTranslate('booking.expiredModal');
    const [searchParams, setSearchParams] = useSearchParams();

    const onCloseHandler = () => {
        searchParams.delete('expired');
        setSearchParams(searchParams);
    };

    return (
        <Modal isOpen={expired} onClose={onCloseHandler} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton
                    _hover={{
                        color: 'brand.primary'
                    }}
                    border="none"
                    outline="none"
                />

                <ModalHeader marginTop="36px" paddingBottom="0" textAlign="center">
                    <Heading
                        as="h2"
                        marginBottom="0"
                        size="lg"
                        textAlign="center"
                        variant="primary"
                    >
                        {t('header')}
                    </Heading>
                </ModalHeader>

                <ModalBody position="relative">
                    <Box paddingBottom="120px">
                        <Text
                            color="gray.500"
                            fontFamily="open-sans, sans-serif"
                            fontSize="16px"
                            fontWeight="400"
                        >
                            {t('notice')}
                        </Text>
                    </Box>
                    <Box
                        backgroundColor="background"
                        borderRadius="0px 0px 20px 20px"
                        bottom="0"
                        boxShadow="0px -2px 8px 0px rgba(0, 0, 0, 0.05)"
                        left="0"
                        paddingBottom="30px"
                        paddingTop="30px"
                        position="absolute"
                        role="region"
                        width="100%"
                    >
                        <ButtonGroup spacing="16px" justifyContent="center" width="100%">
                            <Button
                                type="submit"
                                width={230}
                                text={t('close')}
                                onClick={onCloseHandler}
                                variant="primary"
                            />
                        </ButtonGroup>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
