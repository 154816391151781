import { useEffect, useState } from 'react';
import { FormikErrors } from 'sp-ui';
import { FetchMethod, useClientApiFetch } from 'client_react/bootstrap';
import { IEmailPostResponse } from 'client_react/brand/types';
import { isObject } from 'client_react/brand/utils';

interface IContactFormValues {
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    message: string;
}
interface IUsePostContactForm {
    errors: FormikErrors<IContactFormValues>;
    postContactForm: (formValues: IContactFormValues) => void;
    submitted: boolean;
}

export const usePostContactForm = (): IUsePostContactForm => {
    const [errors, setErrors] = useState({});
    const { response, performFetch: sendPost } = useClientApiFetch<IEmailPostResponse>('email', {
        defer: true,
        method: FetchMethod.POST
    });

    const postContactForm = (formValues: IContactFormValues) => {
        setErrors([]);

        return sendPost({
            data: {
                type: 'email',
                ...formValues
            }
        });
    };

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!response) {
            return;
        }

        if (response.status === 202) {
            setSubmitted(true);
            return;
        }

        const { errors: responseErrors } = response.info;

        if (responseErrors) {
            const mappedErrors = Object.entries(responseErrors).map(([field, error]) => {
                if (isObject(error)) {
                    const errorMessage = Object.values(error);

                    return [field, errorMessage];
                }

                return [field, ''];
            });

            setErrors(Object.fromEntries(mappedErrors));
        }
    }, [response]);

    return { errors, postContactForm, submitted };
};
