import { IFetchHookOptions, useFetch } from 'ts/common/hooks';

let baseUrl: string;

export const setBaseUrl = (url: string) => {
    baseUrl = url;
};

const useClientApiFetch = <TResponse>(path: string, fetchOptions?: IFetchHookOptions) => {
    return useFetch<TResponse>(`${baseUrl}/${path}`, fetchOptions);
};

export default useClientApiFetch;
