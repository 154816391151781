import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BoxProps, IconButton, Image } from '@chakra-ui/react';
import { Flex, styled, useTheme } from 'sp-ui';
import { ReactComponent as LeftCaret } from 'svg/common/left-caret-thin.svg';
import { ReactComponent as RightCaret } from 'svg/common/right-caret-thin.svg';
import { useTranslate } from 'ts/common/hooks';

const ImageContainer = styled(Flex)(
    ({ theme }) => `
        background: ${theme.colors.gray[100]};
        box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0.02);
        overflow: hidden;
    `
);

const ActiveImageContainer = styled(ImageContainer)(
    () => `
        aspect-ratio: 1 / 1;
        width: 100%;
    `
);

const CarouselImage = styled(Image)(
    () => `
        margin: auto;
        max-height: 100%;
        max-width: 100%;
    `
);

const Container = styled(Flex)(
    () => `
        align-items: center;
        flex-direction: column;
        gap: 16px;
    `
);

const FilmStrip = styled(Flex)(
    () => `
        align-items: center;
        gap: 8px;
    `
);

const ThumbnailImageContainer = styled(ImageContainer, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'highlightColor'
})(
    ({ highlightColor, isSelected }: { highlightColor: string; isSelected: boolean }) => `
        border-radius: 10px;
        cursor: pointer;
        height: 60px;
        width: 60px;

        &:hover {
            border: 1.5px solid ${highlightColor};
        }

        ${isSelected && `border: 1.5px solid ${highlightColor};`}
    `
);

interface IImageCarouselProps {
    belowActiveImageContent?: React.ReactNode;
    highlightColor?: string;
    images: (React.ReactNode | string)[];
}

const ImageCarousel: React.FC<IImageCarouselProps & BoxProps> = ({
    belowActiveImageContent,
    highlightColor,
    images,
    ...props
}) => {
    const FILMSTRIP_THUMBNAIL_WIDTH = 68;
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const activeImage = images[activeImageIndex];
    const carouselRef = useRef<HTMLDivElement>(null);
    const [filmstripSlidingWindowIndex, setFilmstripSlidingWindowIndex] = useState(0);

    const handleResize = useCallback(() => {
        if (carouselRef.current) {
            setMaxFilmstripLength(
                Math.floor(carouselRef.current.offsetWidth / FILMSTRIP_THUMBNAIL_WIDTH)
            );
        }
    }, []);

    const hasMultipleImages = images.length > 1;
    const [maxFilmstripLength, setMaxFilmstripLength] = useState(1);
    const hasScrollingEnabled = images.length > maxFilmstripLength;
    const scrollingFilmstripLength = maxFilmstripLength - 1;

    const isIndexInFilmstripWindow = useCallback(
        (index: number) => {
            return (
                index >= filmstripSlidingWindowIndex &&
                index < filmstripSlidingWindowIndex + scrollingFilmstripLength
            );
        },
        [filmstripSlidingWindowIndex, scrollingFilmstripLength]
    );

    const t = useTranslate();
    const theme = useTheme();

    useEffect(() => {
        setActiveImageIndex((activeImageIndex) => {
            return activeImageIndex >= images.length ? 0 : activeImageIndex;
        });
    }, [images]);

    useLayoutEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <Container ref={carouselRef} width="100%" {...props}>
            <ActiveImageContainer data-testid="active-carousel-image-container">
                {typeof activeImage === 'string' ? (
                    <CarouselImage
                        alt={t('activeCarousel')}
                        data-testid="active-carousel-image"
                        src={activeImage}
                    />
                ) : (
                    activeImage
                )}
            </ActiveImageContainer>
            {belowActiveImageContent}
            {hasMultipleImages && (
                <FilmStrip data-testid="carousel-filmstrip">
                    {hasScrollingEnabled && (
                        <IconButton
                            aria-label={t('backLabel')}
                            data-testid="left-scroll-button"
                            isDisabled={filmstripSlidingWindowIndex === 0}
                            marginRight="16px"
                            onClick={() => {
                                setFilmstripSlidingWindowIndex(
                                    (filmstripSlidingWindowIndex) => filmstripSlidingWindowIndex - 1
                                );
                            }}
                            variant="ghost"
                        >
                            <LeftCaret />
                        </IconButton>
                    )}
                    {images.map(
                        (image, index) =>
                            (!hasScrollingEnabled || isIndexInFilmstripWindow(index)) && (
                                <ThumbnailImageContainer
                                    highlightColor={highlightColor || theme.colors.blue[400]}
                                    isSelected={index === activeImageIndex}
                                    key={index}
                                    onClick={() => {
                                        setActiveImageIndex(index);
                                    }}
                                >
                                    {typeof image === 'string' ? (
                                        <CarouselImage
                                            alt={`${t('carouselThumbnail')}${index + 1}`}
                                            data-testid="carousel-filmstrip-thumbnail"
                                            src={image}
                                        />
                                    ) : (
                                        image
                                    )}
                                </ThumbnailImageContainer>
                            )
                    )}
                    {hasScrollingEnabled && (
                        <IconButton
                            aria-label={t('nextLabel')}
                            data-testid="right-scroll-button"
                            isDisabled={
                                filmstripSlidingWindowIndex + scrollingFilmstripLength >=
                                images.length
                            }
                            marginLeft="16px"
                            onClick={() => {
                                setFilmstripSlidingWindowIndex(
                                    (filmstripSlidingWindowIndex) => filmstripSlidingWindowIndex + 1
                                );
                            }}
                            variant="ghost"
                        >
                            <RightCaret />
                        </IconButton>
                    )}
                </FilmStrip>
            )}
        </Container>
    );
};

export default ImageCarousel;
