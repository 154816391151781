import React from 'react';
import { createRoot } from 'react-dom/client';
import { setBaseUrl } from 'ts/client';
import ClientApp from './ClientApp';

const clientReactAppElement = document.getElementById('clientReactApp');

const gRecaptchaKey = clientReactAppElement?.getAttribute('data-g-recaptcha-key') ?? '';
const apiRoot = clientReactAppElement?.getAttribute('data-api-root') ?? '';

setBaseUrl(apiRoot);

createRoot(clientReactAppElement!).render(<ClientApp gRecaptchaKey={gRecaptchaKey} />);
