import { H1, P, styled } from 'sp-ui';

export const ThemedHeading = styled(H1)(
    ({ theme }) => `
        color: ${theme.brand.colors.user.primary};
        text-align: center;
        font-size: 28px;
        font-family: ${theme.brand.fonts.primary};
        letter-spacing: 3px;
        font-weight: 700;
        line-height: 36px;
        padding: 0 0 48px 0;
        text-transform: uppercase;
    `
);
export const ThemedParagraph = styled(P)`
    flex-direction: column;
    text-align: center;
    font-family; 'OpenSans Regular';
    color: ${({ theme }) => theme.brand.colors.common.gray};
`;
