import React, { createContext, useContext, useState } from 'react';
import { BrandHomepageLayout } from '../common';
import { IBrandHomepage } from '../types';

interface IBrandHomepageContext {
    brandHomepage: IBrandHomepage;
}
interface IBrandHomepageProviderProps {
    children?: React.ReactNode;
    brandHomepage: IBrandHomepage;
}

const BrandHomepageContext = createContext<IBrandHomepageContext>({
    brandHomepage: {
        aboutText: null,
        aboutImage: null,
        brandTheme: {
            colorScheme: 'light',
            created: '',
            emailBrandLogo: null,
            eventBrandLogo: null,
            fontSet: 'brandon',
            id: 0,
            logoAltText: '',
            name: '',
            primaryColor: '',
            secondaryColor: '',
            type: 'brand-theme'
        },
        enableAboutPage: false,
        enableContactForm: false,
        homepageText: null,
        layout: BrandHomepageLayout.Events,
        address: null,
        phone: null,
        email: null,
        facebookUrl: null,
        instagramUrl: null,
        pinterestUrl: null,
        twitterUrl: null,
        website: null
    }
});

const BrandHomepageProvider: React.FC<IBrandHomepageProviderProps> = ({
    brandHomepage,
    ...props
}) => {
    const [storedBrandHomepage] = useState(brandHomepage);

    return (
        <BrandHomepageContext.Provider value={{ brandHomepage: storedBrandHomepage }} {...props} />
    );
};

export const withBrandHomepage = <T extends object>(
    Component: React.ComponentType<T>
): React.FC<T & { brandHomepage: IBrandHomepage }> => {
    const WithBrandHomepageComponent: React.FC<T & { brandHomepage: IBrandHomepage }> = (props) => {
        return (
            <BrandHomepageProvider brandHomepage={props.brandHomepage}>
                <Component {...props} />
            </BrandHomepageProvider>
        );
    };

    return WithBrandHomepageComponent;
};

export function useBrandHomepage(): IBrandHomepage {
    const { brandHomepage } = useContext(BrandHomepageContext);

    return brandHomepage;
}
