const defaultParameters = {};

export default {
    get,
    getHref,
    setDefaultParameter
};

function get(route, parameters = {}) {
    const RELATIVE_URI_ROUTE_PARAMETER_TOKEN_REG_EXP = /\:[A-Za-z0-9\_\-\?]+/g;
    const routeParameters = route.match(RELATIVE_URI_ROUTE_PARAMETER_TOKEN_REG_EXP) || [];
    const { route: relativeUri, parameters: queryStringParameters } = routeParameters.reduce(
        parseRouteToUri,
        { parameters, route }
    );

    if (Object.keys(queryStringParameters).length > 0) {
        const queryString = getQueryString(queryStringParameters);

        return `${relativeUri}${queryString}`;
    }

    return relativeUri;
}

function getHref(route, parameters = {}) {
    return get(route, parameters).slice(1);
}

function getQueryString(parameters) {
    const queryStringFieldValuePairs = Object.keys(parameters).map((field) => {
        const value = parameters[field];

        return getQueryStringFieldValuePair(field, value);
    });

    return `?${queryStringFieldValuePairs.join('&')}`;
}

function getQueryStringFieldValuePair(field, value) {
    if (Array.isArray(value)) {
        const values = value.map((value) => `${value}&`);

        values.unshift('');

        return values.join(`${field}[]=`).slice(0, -1);
    }

    return `${field}=${encodeURIComponent(value || '')}`;
}

function isOptionalRouteParameter(routeParameter) {
    return routeParameter.indexOf('?') >= 0;
}

function parseRouteToUri({ parameters, route: uri }, routeParameter) {
    const routeParameterKey = routeParameter.replace(/[\:\?]/g, '');
    const routeParameterValue =
        parameters[routeParameterKey] || defaultParameters[routeParameterKey];

    if (routeParameterValue) {
        uri = uri.replace(routeParameter, encodeURIComponent(routeParameterValue));
    }

    if (parameters.hasOwnProperty(routeParameterKey) || isOptionalRouteParameter(routeParameter)) {
        uri = uri.replace(routeParameter, '');
    }

    delete parameters[routeParameterKey];

    return { parameters, route: uri };
}

function setDefaultParameter(key, value) {
    defaultParameters[key] = value;
}
