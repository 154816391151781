import React, { forwardRef } from 'react';
import { Box, styled } from 'sp-ui';
import { ImagePlaceholder as ImagePlaceholderIcon } from 'client_react/common/components/icons';

interface ICoverPhotoProps {
    focalPointValues: IFocalPointValues;
    imageUrl: Nullable<string>;
}

interface IFocalPointValues {
    width: string;
    height: string;
    offsetX: string;
    offsetY: string;
}

const CoverPhotoContainer = styled(Box)(
    ({ theme }) => `
    @media only screen and (min-width: 1280px) {
        height: 237px;
        width: 360px;
    }

    height: 184px;
    width: 280px;
    margin-bottom: 20px;
    box-shadow: inset 0px 0px 0px 1px ${theme.brand.colors.theme.secondary.medium};
`
);
const PlaceholderContainer = styled(CoverPhotoContainer)(
    ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.brand.colors.theme.secondary.light};

    svg {
        height: 35px;
        width: 35px;
        fill: ${theme.brand.colors.theme.secondary.medium};
    }
`
);

const ImagePlaceholder = (props) => {
    return (
        <PlaceholderContainer {...props}>
            <ImagePlaceholderIcon />
        </PlaceholderContainer>
    );
};

const ImageContainer = styled(CoverPhotoContainer, {
    shouldForwardProp: (prop) => prop !== 'imageUrl' && prop !== 'focalPointValues'
})(({ focalPointValues, imageUrl }: ICoverPhotoProps) => {
    return `
        background-image: url('${imageUrl}');
        background-size: ${focalPointValues.width} ${focalPointValues.height};
        background-position: ${focalPointValues.offsetX} ${focalPointValues.offsetY};
        background-repeat: no-repeat;
    `;
});

const CoverPhoto = forwardRef<HTMLDivElement, ICoverPhotoProps>(
    ({ imageUrl = null, focalPointValues }, ref) => {
        return imageUrl === null ? (
            <ImagePlaceholder data-testid="brand-homepage-tile-no-image" />
        ) : (
            <ImageContainer
                data-testid="brand-homepage-tile-image"
                focalPointValues={focalPointValues}
                imageUrl={imageUrl}
                ref={ref}
            />
        );
    }
);

CoverPhoto.displayName = 'CoverPhoto';

export { CoverPhoto };
