import { useCallback } from 'react';
import { useTranslate as useBaseTranslate } from 'sp-ui';

enum TranslationRoots {
    Studio = 'studio'
}

type UseTranslateHook = (keyBase?: string) => (key: string, ...args: unknown[]) => string;

/**
 * Translate hook that is bindable to a given a base path for translation
 * keys.
 *
 * @example Using without `keyBase`
 * const t = useTranslate();
 * const translatedText = t('studio.event.volume.somePath');
 * // returns translated string for 'studio.event.volume.somePath'
 *
 * @example Using with `keyBase`
 * const t = useTranslate('studio.event.volume');
 * const translatedText = t('somePath');
 * // returns translated string for path `studio.event.volume.somePath`
 *
 * @example Bypass `keyBase` with an absolute path
 * const t = useTranslate('studio.event.volume')
 * const translatedText = t('studio.someotherplace.header');
 * // returns translated string for path `studio.someotherplace.header
 **/

export const useTranslate: UseTranslateHook = (keyBase = '') => {
    const translate = useBaseTranslate();

    return useCallback(
        (key: string, ...args) => {
            const fullKey = shouldSkipBase(keyBase, key) ? key : `${keyBase}.${key}`;

            return translate(fullKey, ...args);
        },
        [keyBase, translate]
    );
};

const shouldSkipBase = (keyBase, key) => {
    return !keyBase.length || key.indexOf(`${TranslationRoots.Studio}.`) === 0;
};
