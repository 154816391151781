const CLIENT_GUTTER_SIZES = {
    SMALL: 3,
    MEDIUM: 20,
    LARGE: 40
};
const CLIENT_THUMBNAIL_SIZES = {
    HMASON: {
        SMALL: 320,
        MEDIUM: 480,
        LARGE: 640
    },
    VMASON: {
        SMALL: 190,
        MEDIUM: 380,
        LARGE: 570
    }
};
const CLIENT_THUMBNAIL_SIZE_NAMES = {
    SMALL: 's',
    MEDIUM: 'm',
    LARGE: 'l'
};
const CLIENT_THUMBNAIL_GUTTER_MAP = {
    [CLIENT_THUMBNAIL_SIZE_NAMES.SMALL]: CLIENT_GUTTER_SIZES.SMALL,
    [CLIENT_THUMBNAIL_SIZE_NAMES.MEDIUM]: CLIENT_GUTTER_SIZES.MEDIUM,
    [CLIENT_THUMBNAIL_SIZE_NAMES.LARGE]: CLIENT_GUTTER_SIZES.LARGE
};
const CLIENT_THUMBNAIL_SIZE_MAP = {
    HMASON: {
        [CLIENT_THUMBNAIL_SIZE_NAMES.SMALL]: CLIENT_THUMBNAIL_SIZES.HMASON.SMALL,
        [CLIENT_THUMBNAIL_SIZE_NAMES.MEDIUM]: CLIENT_THUMBNAIL_SIZES.HMASON.MEDIUM,
        [CLIENT_THUMBNAIL_SIZE_NAMES.LARGE]: CLIENT_THUMBNAIL_SIZES.HMASON.LARGE
    },
    VMASON: {
        [CLIENT_THUMBNAIL_SIZE_NAMES.SMALL]: CLIENT_THUMBNAIL_SIZES.VMASON.SMALL,
        [CLIENT_THUMBNAIL_SIZE_NAMES.MEDIUM]: CLIENT_THUMBNAIL_SIZES.VMASON.MEDIUM,
        [CLIENT_THUMBNAIL_SIZE_NAMES.LARGE]: CLIENT_THUMBNAIL_SIZES.VMASON.LARGE
    }
};
const DOWNLOAD_DESTINATION = {
    DEVICE: 'device',
    GOOGLE_PHOTOS: 'googlePhotos'
};
const DOWNLOAD_TYPE = {
    ALL: 'all',
    ALBUM: 'album',
    PHOTO: 'photo'
};
const PACKAGE_SMALL_SCREEN_STATES = {
    PHOTO_VIEW: 'package-small-screen-states-photo-view',
    PACKAGE_VIEW: 'package-small-screen-states-package-view'
};

export {
    CLIENT_GUTTER_SIZES,
    CLIENT_THUMBNAIL_GUTTER_MAP,
    CLIENT_THUMBNAIL_SIZES,
    CLIENT_THUMBNAIL_SIZE_MAP,
    CLIENT_THUMBNAIL_SIZE_NAMES,
    DOWNLOAD_DESTINATION,
    DOWNLOAD_TYPE,
    PACKAGE_SMALL_SCREEN_STATES
};

export default {
    CLIENT_GUTTER_SIZES,
    CLIENT_THUMBNAIL_GUTTER_MAP,
    CLIENT_THUMBNAIL_SIZES,
    CLIENT_THUMBNAIL_SIZE_MAP,
    CLIENT_THUMBNAIL_SIZE_NAMES,
    DOWNLOAD_DESTINATION,
    DOWNLOAD_TYPE,
    PACKAGE_SMALL_SCREEN_STATES
};
