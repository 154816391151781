import SmoothScroll from 'smooth-scroll';

const scrollTo = (position, element = null) => {
    new SmoothScroll().animateScroll(position, element, {
        easing: 'easeInOutQuart',
        speed: 500,
        speedAsDuration: true
    });
};

export { scrollTo };
