import { useCallback, useEffect, useRef, useState } from 'react';

const defaultOptions: IFetchHookOptions = {
    defer: false
};
const inProgressGetRequestPromises = {};

export enum FetchContentType {
    Json = 'application/json',
    ProblemJson = 'application/problem+json',
    SpJson = 'application/vnd.shootproof+json'
}

export enum FetchMethod {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT'
}

export type IFetchHookOptions = {
    /**
     * Contains additional request options for `fetch`.
     */
    data?: object;
    /**
     * Determines if `fetch` should be performed immediately or defered to the explicit `performFetch` call. Defaults to false.
     */
    defer?: boolean;
    /**
     * Should be set to true if the the `useFetch` hook's URL may change during rendering.
     */
    fetchOnUrlChange?: boolean;
    /**
     * Params to be added to the `fetch` URL. Should not include preceding `?`
     */
    search?: Record<string, string>;
    /**
     * URL to use for request. URL is appended to base URL when provided on `fetchResponse` call.
     */
    url?: string;
} & RequestInit;

export type IFetchHook<TResponse = unknown> = {
    response: IFetchHookResponse<TResponse>;
    loading: boolean;
    performFetch: (fetchOptions?: IFetchHookOptions) => Promise<IFetchHookResponse<TResponse>>;
};

export type IFetchHookResponse<T> =
    | (T & {
          status: number;
      })
    | null;

const preprocessOptions = (options) => ({
    ...options,
    ...(options.method && { method: options.method.toUpperCase() })
});
const isGetMethod = (method) => method === FetchMethod.GET;

/**
 * To make a GET request to a provided 'url' (default behavior)
 * useFetch('url');
 *
 * To apply a query parameter to the request
 * useFetch('url', { search: { filterById: '1234' } });
 *
 * Or to conditionally apply a query parameter
 * const { performFetch } = useFetch('url');
 * performFetch({ search: { filterById: '1234' } });
 *
 * To make a POST/PATCH/PUT request to a provided 'url'
 * useFetch(
 *      'url',
 *      {
 *          method: FetchMethod.POST,
 *          data: { propertyToPost: foo }
 *      }
 * );
 */
export function useFetch<TResponse = unknown>(
    url: string,
    fetchOptions: IFetchHookOptions = { ...defaultOptions }
): IFetchHook<TResponse> {
    let { defer } = fetchOptions;

    delete fetchOptions.defer;

    const [doFetch, setDoFetch] = useState(!defer);
    const fetchOptionsRef = useRef(fetchOptions);
    const fetchResponse = useCallback(
        async (options: IFetchHookOptions) => {
            const { method = FetchMethod.GET } = options;
            const hasBody = [FetchMethod.PATCH, FetchMethod.POST, FetchMethod.PUT].some(
                (methodWithBody) => methodWithBody === method
            );

            if (hasBody && options.data) {
                const { data } = options;
                const defaultPostHeaders = { 'Content-Type': FetchContentType.SpJson };

                if (data) {
                    options.body = JSON.stringify(data);

                    delete options.data;
                }

                options.headers = {
                    ...defaultPostHeaders,
                    ...(options.headers || {})
                };
            }

            let fetchResponse;

            try {
                const search = options.search;
                const fetchUrl = new URL(
                    options.url ? (url ? `${url}/${options.url}` : options.url) : url
                );

                if (options.url) {
                    delete options.url;
                }

                if (search) {
                    Object.entries(search).forEach(([key, value]) =>
                        fetchUrl.searchParams.set(key, value)
                    );
                    delete options.search;
                }

                fetchResponse = await fetch(fetchUrl.href, options);
            } catch (ex) {
                fetchResponse = { status: 500 };
            }

            const { status } = fetchResponse;
            const response = [
                FetchContentType.Json,
                FetchContentType.ProblemJson,
                FetchContentType.SpJson
            ].includes(fetchResponse.headers.get('Content-Type'))
                ? await fetchResponse.json()
                : { response: fetchResponse };

            return { ...response, status };
        },
        [url]
    );
    const { fetchOnUrlChange = false } = fetchOptions;
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(!defer);
    const [options, setOptions] = useState(preprocessOptions(fetchOptionsRef.current));

    const performFetch = useCallback(async (fetchOptions: IFetchHookOptions = {}) => {
        const responsePromise = new Promise<IFetchHookResponse<TResponse>>((resolve) => {
            responseResolve.current = resolve;
        });

        setTimeout(() => {
            const options = {
                ...fetchOptionsRef.current,
                ...fetchOptions
            };

            if (fetchOptionsRef.current.data || fetchOptions.data) {
                options.data = {
                    ...fetchOptionsRef.current.data,
                    ...fetchOptions.data
                };
            }

            if (fetchOptionsRef.current.headers || fetchOptions.headers) {
                options.headers = {
                    ...fetchOptionsRef.current.headers,
                    ...fetchOptions.headers
                };
            }

            setOptions(preprocessOptions(options));
            setDoFetch(true);
            setHasLoaded(true);
        });

        return await responsePromise;
    }, []);
    const [response, setResponse] = useState<IFetchHookResponse<TResponse>>(null);
    const responseResolve = useRef<(response: IFetchHookResponse<TResponse>) => void>();

    defer = defer && !hasLoaded;

    useEffect(() => {
        let unmounted = false;

        if (doFetch) {
            const { method = FetchMethod.GET } = options;
            const isGetRequest = isGetMethod(method);
            let fetchPromise;

            delete options.fetchOnUrlChange;
            setLoading(true);
            setResponse(null);

            if (isGetRequest) {
                fetchPromise = inProgressGetRequestPromises[url];

                if (!fetchPromise) {
                    fetchPromise = fetchResponse(options);
                    inProgressGetRequestPromises[url] = fetchPromise;
                }
            } else {
                fetchPromise = fetchResponse(options);
            }

            fetchPromise
                .then((response: IFetchHookResponse<TResponse>) => {
                    if (!unmounted) {
                        setDoFetch(false);
                        setResponse(response);
                        setLoading(false);

                        if (responseResolve.current) {
                            responseResolve.current(response);
                        }
                    }
                })
                .catch(() => {
                    if (!unmounted) {
                        setDoFetch(false);
                        setResponse(null);
                        setLoading(false);

                        if (responseResolve.current) {
                            responseResolve.current(null);
                        }
                    }
                })
                .finally(() => {
                    if (isGetRequest) {
                        delete inProgressGetRequestPromises[url];
                    }
                });
        }

        return () => {
            unmounted = true;
        };
    }, [doFetch, fetchResponse, options, url]);

    useEffect(() => {
        setDoFetch(!defer);
    }, [defer]);

    useEffect(() => {
        const { method } = options;

        if (!method || isGetMethod(method) || fetchOnUrlChange) {
            setDoFetch(!defer);
        }
    }, [defer, fetchOnUrlChange, options, url]);

    return { response, loading, performFetch };
}
