import React, { FC, useEffect } from 'react';
import { BrandHomepageLayout } from '../common';
import BackToTop from '../components/BackToTop';
import CategoriesLayout from '../components/CategoriesLayout';
import EventsLayout from '../components/EventsLayout';
import LoadMoreButton from '../components/LoadMoreButton';
import { PageText, TileContainer } from '../components/common';
import { useEventCategoriesData } from '../data/eventCategoriesData';
import { useEventsData } from '../data/eventsData';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';

const IndexPage: FC = () => {
    const { layout: brandHomepageLayout, homepageText } = useBrandHomepage();
    const { categories, loadCategories } = useEventCategoriesData();
    const { events, loadEvents } = useEventsData();
    const shouldUseCategoriesLayout = brandHomepageLayout === BrandHomepageLayout.Categories;
    const layout = shouldUseCategoriesLayout ? (
        <>
            <CategoriesLayout eventCategories={categories || []} />
            <EventsLayout events={events} />
        </>
    ) : (
        <EventsLayout events={events} />
    );

    useEffect(() => {
        shouldUseCategoriesLayout
            ? loadEvents({
                  search: {
                      filterNotInCategories: '1'
                  }
              })
            : loadEvents();
    }, [loadEvents, shouldUseCategoriesLayout]);

    useEffect(() => {
        if (categories === null) {
            loadCategories();
        }
    }, [categories, loadCategories]);

    return (
        <>
            {homepageText && (
                <PageText
                    data-testid="brand-homepage-text"
                    dangerouslySetInnerHTML={{
                        __html: homepageText
                    }}
                />
            )}
            <TileContainer>
                {layout}
                <LoadMoreButton />
            </TileContainer>
            <BackToTop />
        </>
    );
};

export default IndexPage;
