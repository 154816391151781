import React, {
    FC,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { IFetchHookOptions } from 'ts/common/hooks/fetch';
import { noop } from 'ts/common/utils';
import api from '../hooks/api';
import { IEvent, IEventListResponse } from '../types';

export interface IEventsDataContext {
    /**
     * Indicates that more events are available to be loaded.
     */
    canLoadMoreEvents: boolean;
    /**
     * Array of all events that have been loaded, both from `loadEvents` and `loadMoreEvents` calls.
     */
    events: IEvent[];
    /**
     * Loads the first page worth of events.
     */
    loadEvents: (options?: IFetchHookOptions) => Promise<IEventListResponse | null>;
    /**
     * Loads subsequent pages of events.  The `options` object cannot be passed here as the `options`
     * used on the initial `loadEvents` call will be reused for all subsequent requests.
     * This function handles requesting events with the same `options` used originally,
     * but for additional pages worth of results.
     */
    loadMoreEvents: () => void;
    /**
     * Indicates whether or not events are loading.
     */
    loading: boolean;
}

export const EventsDataContext = createContext<IEventsDataContext>({
    canLoadMoreEvents: false,
    events: [],
    loadEvents: () => new Promise<IEventListResponse>(() => null),
    loadMoreEvents: noop,
    loading: false
});

interface IEventDataProviderProps {
    children?: ReactNode | undefined;
}

export const EventsDataProvider: FC<IEventDataProviderProps> = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [eventsLoaded, setEventsLoaded] = useState<IEvent[]>([]);
    const location = useLocation();
    const [totalPages, setTotalPages] = useState(0);
    const {
        response: eventResponse,
        loading,
        performFetch: fetchEvents
    } = api.useClientApiFetch<IEventListResponse>('event', {
        defer: true,
        search: {
            page: String(currentPage)
        }
    });
    const [originalFetchOptions, setOriginalFetchOptions] = useState<IFetchHookOptions>({});
    const [newlyLoadedEvents, setNewlyLoadedEvents] = useState<IEvent[] | null>(null);
    const fetchEventsAndSaveOptions = useCallback(
        (options?: IFetchHookOptions) => {
            setEventsLoaded([]);
            setOriginalFetchOptions(options || {});

            return fetchEvents(options);
        },
        [fetchEvents]
    );

    useEffect(() => {
        setCurrentPage(1);
        setEventsLoaded([]);
        setNewlyLoadedEvents(null);
        setTotalPages(0);
    }, [location]);

    useEffect(() => {
        if (newlyLoadedEvents !== null) {
            setEventsLoaded([...eventsLoaded, ...newlyLoadedEvents]);
            setNewlyLoadedEvents(null);
        }
    }, [eventsLoaded, newlyLoadedEvents]);

    useEffect(() => {
        if (eventResponse) {
            setNewlyLoadedEvents([...eventResponse.items]);
            setTotalPages(eventResponse.meta?.totalPages);
        }
    }, [eventResponse]);

    useEffect(() => {
        const nextRequestOptions = { ...originalFetchOptions };
        const search = {
            ...(originalFetchOptions.search || {}),
            ...{
                page: String(currentPage)
            }
        };

        nextRequestOptions.search = search;

        if (currentPage > 1) {
            fetchEvents(nextRequestOptions);
        }
    }, [currentPage, fetchEvents, originalFetchOptions]);

    const eventContext: IEventsDataContext = {
        canLoadMoreEvents: currentPage < totalPages,
        events: eventsLoaded,
        loadEvents: fetchEventsAndSaveOptions,
        loading,
        loadMoreEvents: () => {
            setCurrentPage(currentPage + 1);
        }
    };

    return <EventsDataContext.Provider value={eventContext}>{children}</EventsDataContext.Provider>;
};

/**
 * Used to get access to the events that have been loaded within the context.
 *
 * To retrieve the first page of events available:
 * ```
 * const { canLoadMoreEvents, events, loadEvents, loadMoreEvents} = useEventsData();
 * loadEvents();
 * ```
 *
 * Once loaded, the `events` array will be populated.  As subsequent calls are made to
 * `loadMoreEvents`, the `events` array is appended to with additional results.
 *
 * During usage, `canLoadMoreEvents` indicates if there are additional requests to be
 * made to load more events.
 */
export const useEventsData = () => {
    return useContext(EventsDataContext);
};
