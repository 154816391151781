import React from 'react';
import { A, Flex, styled } from 'sp-ui';
import {
    SocialNetworkIconEmail,
    SocialNetworkIconFacebook,
    SocialNetworkIconInstagram,
    SocialNetworkIconPinterest,
    SocialNetworkIconTwitter,
    SocialNetworkIconWebsite
} from 'client_react/common/components/icons';

enum SocialNetworkIconType {
    Facebook = 'facebook',
    Twitter = 'twitter',
    Instagram = 'instagram',
    Pinterest = 'pinterest',
    Website = 'website',
    Email = 'email'
}
interface ISocialNetworkIconProps {
    destination: string;
    height: string;
    type: SocialNetworkIconType;
}

const SocialIcon = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'height'
})<{ height: string }>(
    ({ height }) => `
        margin: 0 20px;

        svg {
            height: ${height};
            fill: #a5a5a5;
        }
    `
);

const SocialNetworkIcon: React.FC<ISocialNetworkIconProps> = ({ type, height, destination }) => {
    const typeToIconMap = {
        [SocialNetworkIconType.Facebook]: SocialNetworkIconFacebook,
        [SocialNetworkIconType.Twitter]: SocialNetworkIconTwitter,
        [SocialNetworkIconType.Instagram]: SocialNetworkIconInstagram,
        [SocialNetworkIconType.Pinterest]: SocialNetworkIconPinterest,
        [SocialNetworkIconType.Website]: SocialNetworkIconWebsite,
        [SocialNetworkIconType.Email]: SocialNetworkIconEmail
    };
    const typeToAriaLabelMap = {
        [SocialNetworkIconType.Facebook]: 'Facebook',
        [SocialNetworkIconType.Twitter]: 'Twitter',
        [SocialNetworkIconType.Instagram]: 'Instagram',
        [SocialNetworkIconType.Pinterest]: 'Pinterest',
        [SocialNetworkIconType.Website]: 'Website',
        [SocialNetworkIconType.Email]: 'Email'
    };
    const Icon = typeToIconMap[type];

    return (
        <SocialIcon height={height} data-testid="social-icon-container">
            <A href={destination} aria-label={`Link to ${typeToAriaLabelMap[type]}`}>
                {Icon && <Icon data-testid="social-icon" color="blue" />}
            </A>
        </SocialIcon>
    );
};

const SocialIcons = styled(Flex)`
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
`;

interface ISocialNetworkLinks {
    email: Nullable<string>;
    facebookUrl: Nullable<string>;
    instagramUrl: Nullable<string>;
    pinterestUrl: Nullable<string>;
    twitterUrl: Nullable<string>;
    website: Nullable<string>;
}

const SocialNetworkLinks: React.FC<ISocialNetworkLinks> = ({
    email,
    facebookUrl,
    instagramUrl,
    pinterestUrl,
    twitterUrl,
    website
}) => {
    return (
        <SocialIcons data-testid="social-network-links-container">
            {facebookUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Facebook}
                    height="21px"
                    destination={facebookUrl}
                />
            )}
            {twitterUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Twitter}
                    height="18px"
                    destination={twitterUrl}
                />
            )}
            {instagramUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Instagram}
                    height="21px"
                    destination={instagramUrl}
                />
            )}
            {pinterestUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Pinterest}
                    height="21px"
                    destination={pinterestUrl}
                />
            )}
            {website && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Website}
                    height="21px"
                    destination={website}
                />
            )}
            {email && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Email}
                    height="17px"
                    destination={`mailto:${email}`}
                />
            )}
        </SocialIcons>
    );
};

export default SocialNetworkLinks;
