import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

export interface IRowProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export type FullRowProps = IRowProps & BoxProps;

// This component is duplicated from sp-ui for use in migrating components off sp-ui that implement it currently.
const StyledRowBox = styled(Box)(
    ({ theme }) => `
        position: relative;
        width: 100%;
        max-width: ${theme.rowMaxWidth};
        margin: 0 auto;
        margin-bottom: 16px;
    `
);

export const Row: React.FC<FullRowProps> = ({ children, ...props }) => (
    <StyledRowBox {...props}>{children}</StyledRowBox>
);

export default Row;
