import React, { useMemo } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { Link as LinkIcon, Phone } from 'react-feather';
import { useTranslate } from 'ts/common';
import { CalendarIcon, ClockIcon, CurrencyIcon, LocationIcon } from 'ts/common/components/icons';
import { translateTimeUnits, useTranslatedTimeUnits } from 'ts/common/hooks/useTranslatedTimeUnits';
import { getTimeZoneAbbreviation, getTimeZoneName } from 'client_react/booking/helpers';
import { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

const BookingSessionSummary = ({
    bookingSession,
    bookingSessionType,
    isConfirmation
}: {
    bookingSession: Nullable<IBookingSession>;
    bookingSessionType: Nullable<IBookingSessionType>;
    isConfirmation?: boolean;
}) => {
    const t = useTranslate('booking');
    const baseTranslate = useTranslate();

    const durationUnitString = useTranslatedTimeUnits(
        bookingSessionType?.durationUnit ?? null,
        bookingSessionType?.duration
    );

    const { locationIcon, locationValue } = useMemo(() => {
        let locationType;

        if (bookingSession) {
            if (bookingSession.address) {
                locationType = 'address';
            } else if (bookingSession.phoneNumber) {
                locationType = 'phone';
            } else if (bookingSession.url) {
                locationType = 'url';
            }
        }

        locationType = locationType ?? bookingSessionType?.locationType;

        switch (locationType) {
            case 'phone':
                return {
                    locationIcon: <Phone viewBox="-2 -2 28 28" style={{ flex: '0 0 auto' }} />,
                    locationValue: bookingSession?.phoneNumber ?? bookingSessionType?.phoneNumber
                };
            case 'url':
                return {
                    locationIcon: <LinkIcon viewBox="-2 -2 28 28" style={{ flex: '0 0 auto' }} />,
                    locationValue: bookingSession?.url ?? bookingSessionType?.url
                };
            default:
                return {
                    locationIcon: <LocationIcon style={{ flex: '0 0 auto' }} />,
                    locationValue: bookingSession?.address ?? bookingSessionType?.address
                };
        }
    }, [
        bookingSession,
        bookingSessionType?.address,
        bookingSessionType?.locationType,
        bookingSessionType?.phoneNumber,
        bookingSessionType?.url
    ]);

    const endDateTime = bookingSession?.endTime ? new Date(bookingSession.endTime) : null;
    const startDateTime = bookingSession?.startTime ? new Date(bookingSession.startTime) : null;

    // The duration of the booking session is calculated based on the start and end time
    const bookingSessionDuration =
        endDateTime && startDateTime
            ? Math.floor((endDateTime.getTime() - startDateTime.getTime()) / 1000 / 60)
            : null;
    const bookingSessionDurationString = bookingSessionDuration
        ? bookingSessionDuration % 60 === 0
            ? `${bookingSessionDuration / 60} ${translateTimeUnits(
                  baseTranslate,
                  'hour',
                  bookingSessionDuration / 60
              )}`
            : `${bookingSessionDuration} ${translateTimeUnits(
                  baseTranslate,
                  'minute',
                  bookingSessionDuration
              )}`
        : null;
    const bookingSessionTypeDurationString = bookingSessionType?.duration
        ? `${bookingSessionType.duration} ${durationUnitString}`
        : null;
    const durationString = bookingSessionDurationString ?? bookingSessionTypeDurationString;

    const timeZoneAbbreviation = getTimeZoneAbbreviation();
    const timeZoneString = isConfirmation
        ? `(${timeZoneAbbreviation ? timeZoneAbbreviation + ' - ' : ''}${getTimeZoneName()})`
        : timeZoneAbbreviation;

    return (
        <Flex
            flexDirection={{ base: 'column', lg: isConfirmation ? 'column' : 'row' }}
            alignItems={{ base: 'stretch', lg: isConfirmation ? 'flex-start' : 'center' }}
            gap={{ base: '12px', lg: isConfirmation ? '16px' : '32px' }}
            fontWeight={600}
            color="darkText"
            whiteSpace={{ base: 'normal', lg: 'nowrap' }}
        >
            {!isConfirmation && (bookingSessionType?.hasPrice || bookingSession?.hasPrice) && (
                <Flex alignItems="center" gap="8px">
                    <CurrencyIcon style={{ flex: '0 0 auto' }} />
                    <Text color="darkText">{(bookingSession ?? bookingSessionType)?.price}</Text>
                </Flex>
            )}
            {durationString && (
                <Flex alignItems="center" gap="8px">
                    <ClockIcon style={{ flex: '0 0 auto' }} />
                    <Text color="darkText">{durationString}</Text>
                </Flex>
            )}
            {locationValue && (
                <Flex
                    alignItems="center"
                    gap="8px"
                    whiteSpace={{ base: 'normal', lg: 'nowrap' }}
                    overflowX="hidden"
                >
                    {locationIcon}
                    <Text
                        color="darkText"
                        title={locationValue}
                        overflowX="hidden"
                        textOverflow="ellipsis"
                    >
                        {locationValue}
                    </Text>
                </Flex>
            )}
            {bookingSession?.startTime && (
                <Flex alignItems="center" gap="8px">
                    <CalendarIcon style={{ flex: '0 0 auto' }} />
                    <Text color="darkText">
                        {bookingSession.startTime &&
                            new Date(bookingSession.startTime).toLocaleDateString([], {
                                dateStyle: 'full'
                            })}
                        {' - '}
                        {new Date(bookingSession.startTime).toLocaleTimeString([], {
                            timeStyle: 'short'
                        })}{' '}
                        {timeZoneString}
                    </Text>
                </Flex>
            )}
            {isConfirmation && bookingSession && bookingSession.hasPrice && (
                <>
                    <Flex alignItems="center" gap="8px">
                        <CurrencyIcon style={{ flex: '0 0 auto' }} />
                        <Text color="darkText">
                            {t('confirmationPage.paid')}
                            {': '}
                            {bookingSession.pendingAmountPaid}{' '}
                            {bookingSession.amountPaid !== bookingSession.pendingAmountPaid &&
                                t('confirmationPage.pendingConfirmation')}
                            {bookingSession.isConfirmed && bookingSession.invoiceUrl && (
                                <>
                                    {' - '}
                                    <Link
                                        href={bookingSession.invoiceUrl}
                                        color="brand.primary"
                                        target="_blank"
                                    >
                                        {t('confirmationPage.viewInvoice')}
                                    </Link>
                                </>
                            )}
                        </Text>
                    </Flex>
                    {!bookingSession.isPaidInFull && (
                        <Flex alignItems="center" gap="8px">
                            <CurrencyIcon style={{ flex: '0 0 auto' }} />
                            <Text color="darkText">
                                {t('confirmationPage.remainingBalance')}
                                {': '}
                                {bookingSession.pendingAmountDue}{' '}
                                {bookingSession.amountDue !== bookingSession.pendingAmountDue &&
                                    t('confirmationPage.pendingConfirmation')}
                                {bookingSession.isConfirmed && bookingSession.invoiceUrl && (
                                    <>
                                        {' - '}
                                        <Link
                                            href={bookingSession.invoiceUrl}
                                            color="brand.primary"
                                            target="_blank"
                                        >
                                            {t('confirmationPage.makePayment')}
                                        </Link>
                                    </>
                                )}
                            </Text>
                        </Flex>
                    )}
                </>
            )}
        </Flex>
    );
};

export default BookingSessionSummary;
