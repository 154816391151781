import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, H1, styled } from 'sp-ui';
import { useTranslate } from 'ts/common/hooks';
import BackToTop from '../components/BackToTop';
import EventsLayout from '../components/EventsLayout';
import LoadMoreButton from '../components/LoadMoreButton';
import { TileContainer } from '../components/common';
import { useEventCategoriesData } from '../data/eventCategoriesData';
import { useEventsData } from '../data/eventsData';

const AllCategoriesLink = styled(Link)(({ theme }) => {
    return `
        color: ${theme.brand.colors.user.secondary};
        font-family: ${theme.brand.fonts.primary};
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    `;
});
const CategoryNameHeading = styled(H1)(({ theme }) => {
    return `
        color: ${theme.brand.colors.user.primary};
        font-family: ${theme.brand.fonts.primary};
        font-size: 29px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
    `;
});
const HeadingBox = styled(Box)`
    text-align: center;

    a {
        margin-bottom: 13px;
    }

    h1 {
        margin-bottom: 49px;
    }
`;

const CategoryPage: FC = () => {
    const { categories, loadCategories } = useEventCategoriesData();
    const { categoryId } = useParams();
    const { events, loadEvents } = useEventsData();
    const [eventCategoryName, setEventCategoryName] = useState('');
    const t = useTranslate();

    useEffect(() => {
        if (categories === null) {
            loadCategories();
        }
    }, [categories, loadCategories]);

    useEffect(() => {
        loadEvents({
            search: {
                filterEventCategoryId: categoryId || ''
            }
        });
    }, [categoryId, loadEvents]);

    useEffect(() => {
        if (categories === null) {
            return;
        }

        const filteredCategories = categories.filter(
            (resultCategory) => categoryId === String(resultCategory.id)
        );

        if (filteredCategories.length) {
            const [currentCategory] = filteredCategories;

            setEventCategoryName(currentCategory.name);
        }
    }, [categories, categoryId]);

    return (
        <>
            <HeadingBox>
                <AllCategoriesLink to="/">{t('allCategories')}</AllCategoriesLink>
                {eventCategoryName && (
                    <CategoryNameHeading>{eventCategoryName}</CategoryNameHeading>
                )}
            </HeadingBox>
            <TileContainer data-testid="tile-container">
                <EventsLayout events={events} />
                <LoadMoreButton />
            </TileContainer>
            <BackToTop />
        </>
    );
};

export default CategoryPage;
