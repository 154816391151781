import React from 'react';
import { Box, Flex, styled } from 'sp-ui';

interface ILoadingIndicatorProps {
    squareClassName?: string;
}

interface ILoadingIndicatorContainerProps {
    squareCount: number;
    squareDimension: number;
    squareGutter: number;
}

interface ILoadingSquareIndicatorProps {
    className?: string;
    squareDimension: number;
    theme?;
}

const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({ squareClassName }) => {
    const squareCount = 5;
    const squareDimension = 10;
    const squareGutter = 12;

    return (
        <LoadingIndicatorContainer
            className="loading-indicator"
            data-testid="loading-indicator"
            squareCount={squareCount}
            squareDimension={squareDimension}
            squareGutter={squareGutter}
        >
            {Array.from(Array(squareCount).keys()).map((_, index) => (
                <LoadingIndicatorSquare
                    className={squareClassName}
                    key={index}
                    squareDimension={squareDimension}
                />
            ))}
        </LoadingIndicatorContainer>
    );
};

const LoadingIndicatorContainer = styled(Flex, {
    shouldForwardProp: (prop) => !['squareCount', 'squareDimension', 'squareGutter'].includes(prop)
})(
    ({ squareCount, squareDimension, squareGutter }: ILoadingIndicatorContainerProps) => `
        align-items: center;
        justify-content: space-between;
        margin: 40px auto;
        max-width: ${squareCount * squareDimension + (squareCount - 1) * squareGutter}px;
    `
);
const LoadingIndicatorSquare = styled(Box, {
    shouldForwardProp: (prop) => !['squareDimension'].includes(prop)
})(
    ({ className, squareDimension, theme }: ILoadingSquareIndicatorProps) => `
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        animation: fadeIn 0.8s ease-in-out infinite;
        ${!className && `background: ${theme.colors.gray[400]}`};
        height: ${squareDimension}px;
        width: ${squareDimension}px;

        &:nth-of-type(2) {
            animation-delay: 0.1s;
        }

        &:nth-of-type(3) {
            animation-delay: 0.2s;
        }

        &:nth-of-type(4) {
            animation-delay: 0.3s;
        }

        &:nth-of-type(5) {
            animation-delay: 0.4s;
        }
    `
);

export default LoadingIndicator;
