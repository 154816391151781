import React from 'react';
import {
    Box,
    Button,
    Flex,
    Form,
    FormElement,
    FormElementVariant,
    FormikErrors,
    LabelError,
    Row,
    styled,
    useFormikContext,
    yup
} from 'sp-ui';
import { FormErrors } from 'ts/common/components/FormErrors';
import { useTranslate } from 'ts/common/hooks';

interface IContactFormProps {
    onSubmit: (formValues: IContactFormValues) => void;
    errors?: FormikErrors<IContactFormValues>;
}
export interface IContactFormValues {
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactPhone: string;
    message: string;
}

const FormContainer = styled(Row)(
    ({ theme }) => `
        ${theme.responsive.mediumScreenUp`
            max-width: 592px;
            padding: 0;
        `}

        width: 100%;
        padding: 0 24px;

        label:first-of-type {
            font-family; 'OpenSans Regular';
            color: #919191;
        }
    `
);
const CharacterCount = styled(LabelError, {
    shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
    ({ isError }) => `
        position: absolute;
        bottom: ${isError ? '0' : '-27px'};;
        right: -8px;
    `
);
const ContactFormElement = styled(FormElement)(({ theme }) => {
    return `
        background: transparent;
        border-color: ${theme.brand.colors.theme.secondary.medium};
        color: ${theme.brand.colors.common.gray};
        flex: 1;

        &:focus, &:hover, &:active {
            border-color: ${theme.brand.colors.common.gray} !important;
        }
    `;
});
const MessageTextArea = styled(FormElement)(({ theme }) => {
    return `
        border: 1px solid ${theme.brand.colors.theme.secondary.medium};
        box-shadow: none;
        color: ${theme.brand.colors.common.gray};

        &[aria-invalid=true] {
            border-color: ${theme.colors.red[500]};
            box-shadow: none;
        }
    `;
});
const SubmitButton = styled(Button)(({ theme }) => {
    return `
        margin-top: 28px;
        margin-bottom: 28px;
        min-width: 180px;
        background-color: ${theme.brand.colors.user.primary};
        color: ${theme.brand.colors.theme.primary};
        border-color: none;

        &:active {
            background-color: ${theme.brand.colors.user.primary};
        };

        &:hover  {
            background-color: ${theme.brand.colors.user.primary};
        };
    `;
});
const FirstLastNameInputSection = styled(Flex)(({ theme }) => {
    return `
        ${theme.responsive.smallScreenOnly`
            flex-direction: column;
            gap: 0px;
            margin-bottom: 20px;
        `}

        justify-content: space-between;
        gap: 20px;
    `;
});
const ContactInfoInputSection = styled(Flex)(
    ({ theme }) => `
        ${theme.responsive.smallScreenOnly`
            flex-direction: column;
            gap: 0px;
            margin-bottom: 20px;
        `}

        justify-content: space-between;
        gap: 20px;
    `
);

const ContactFormContent: React.FC = () => {
    const t = useTranslate('contactPage');
    const { isSubmitting, values, touched, errors } = useFormikContext<IContactFormValues>();

    return (
        <FormContainer>
            <FirstLastNameInputSection>
                <Box flex="1" marginBottom="20px">
                    <ContactFormElement
                        data-testid="brand-contact-first-name"
                        label={t('firstName')}
                        isRequired
                        name="contactFirstName"
                        showValidationIcons={false}
                    />
                </Box>
                <Box flex="1">
                    <ContactFormElement
                        data-testid="brand-contact-last-name"
                        label={t('lastName')}
                        isRequired
                        name="contactLastName"
                        showValidationIcons={false}
                    />
                </Box>
            </FirstLastNameInputSection>
            <ContactInfoInputSection>
                <Box flex="1" marginBottom="20px">
                    <ContactFormElement
                        data-testid="brand-contact-email"
                        label={t('email')}
                        isRequired
                        name="contactEmail"
                        showValidationIcons={false}
                    />
                </Box>
                <Box flex="1">
                    <ContactFormElement
                        data-testid="brand-contact-phone"
                        label={t('phone')}
                        name="contactPhone"
                        type="tel"
                        showValidationIcons={false}
                    />
                </Box>
            </ContactInfoInputSection>
            <Row>
                <MessageTextArea
                    data-testid="brand-contact-message"
                    label={t('message')}
                    isRequired
                    maxLength={2000}
                    name="message"
                    resize="vertical"
                    variant={FormElementVariant.TextArea}
                />
                <CharacterCount isError={!!touched.message && !!errors.message}>
                    {values.message.length}/2000
                </CharacterCount>
            </Row>
            <Flex width="100%" alignItems="center" justifyContent="center">
                <SubmitButton isDisabled={isSubmitting}>{t('submitButton')}</SubmitButton>
            </Flex>
        </FormContainer>
    );
};

const ContactForm: React.FC<IContactFormProps> = ({ errors = {}, onSubmit }) => {
    const initialFormValues = {
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        message: ''
    };
    const t = useTranslate('contactPage');
    const validationSchema = yup.object().shape({
        contactFirstName: yup.string().required(t('requiredMessage')),
        contactLastName: yup.string().required(t('requiredMessage')),
        contactEmail: yup.string().email(t('validEmailMessage')).required(t('requiredMessage')),
        message: yup.string().required(t('requiredMessage'))
    });

    return (
        <Form<IContactFormValues>
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <FormErrors<IContactFormValues> errors={errors} />
            <ContactFormContent />
        </Form>
    );
};

export default ContactForm;
