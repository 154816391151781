import React, { useEffect, useMemo } from 'react';
import { Box, ChakraProvider, Flex, Heading, extendTheme } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { withI18NextTranslate } from 'sp-ui';
import { useClientApiFetch } from 'ts/client/common';
import { CoverPhoto, getTheme } from 'ts/common/components/gallery';
import { useBootstrapData, withBootstrapData } from 'client_react/bootstrap';
import ActionBox from 'client_react/brand/components/unsubscribe/ActionBox';

interface IHeaderProps {
    brandName: string;
}

const Header = ({ brandName }: IHeaderProps) => {
    return (
        <Flex
            width="full"
            height={{ base: '50px', lg: '80px' }}
            backgroundColor="paper"
            alignItems="center"
        >
            <Heading as="h2" size="sm" color="brand.primary" margin={0} marginLeft="16px">
                {brandName}
            </Heading>
        </Flex>
    );
};

const UnsubscribePage = withBootstrapData(() => {
    const { translations } = useBootstrapData();

    const PageContainerWithTranslations = useMemo(
        () =>
            withI18NextTranslate<{ children: React.ReactNode }>(
                ({ children }: { children: React.ReactNode }) => (
                    <Box background="paper" height="100%" width="100%">
                        {children}
                    </Box>
                ),
                translations
            ),
        [translations]
    );

    const [searchParams] = useSearchParams();
    const unsubscribeToken = searchParams.get('d');

    const { response: decodedTokenResponse } =
        useClientApiFetch<SpApi.Client.IDecodedUnsubscribeToken>(
            `unsubscribe-token/${unsubscribeToken}`
        );

    const { brandId, email, eventId } = decodedTokenResponse || {};

    const { response: event, performFetch: fetchEvent } = useClientApiFetch<SpApi.Client.IEvent>(
        `brand/${brandId}/event/${eventId}`,
        {
            defer: true
        }
    );

    const { response: subscription, performFetch: fetchSubscription } =
        useClientApiFetch<SpApi.Client.ISubscription>(
            `brand/${brandId}/event/${eventId}/subscription`,
            {
                defer: true
            }
        );

    useEffect(() => {
        if (brandId && email && eventId) {
            fetchEvent();
            fetchSubscription({ search: { email } });
        }
    }, [brandId, email, eventId, fetchEvent, fetchSubscription]);

    if (!unsubscribeToken) {
        window.location.replace(new URL(window.location.origin).toString());
    }

    if (!decodedTokenResponse || !event) {
        return null;
    }

    const theme = extendTheme(getTheme(event.brandTheme));

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <PageContainerWithTranslations>
                <Header brandName={decodedTokenResponse.brandName} />
                <Box
                    position="absolute"
                    width="100%"
                    height="360px"
                    backgroundColor="brand.primary"
                >
                    {event.coverPhoto && (
                        <CoverPhoto
                            coverPhoto={{
                                focalPoint: event.coverPhoto.focalPointPercentages,
                                height: event.coverPhoto.height,
                                width: event.coverPhoto.width
                            }}
                            coverPhotoUrl={event.coverPhoto.displayUrl.large}
                        />
                    )}
                </Box>
                {subscription && (
                    <ActionBox
                        decodedToken={decodedTokenResponse}
                        isInitiallySubscribed={subscription.state === 'subscribed'}
                    />
                )}
            </PageContainerWithTranslations>
        </ChakraProvider>
    );
});

export default UnsubscribePage;
