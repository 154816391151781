import React, { FC, useContext } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { DatePickerContext } from './DatePicker';

export interface IDatePickerHeaderControlsProps {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
}

const DatePickerHeaderControls: FC<IDatePickerHeaderControlsProps> = ({
    date,
    decreaseMonth,
    increaseMonth
}) => {
    const { centeredDate } = useContext(DatePickerContext);

    const theme = useTheme();
    const { color } = theme.components.DatePicker.baseStyle;

    return (
        <Box paddingBottom="16px" borderBottom={`1px solid ${color}`} color={color}>
            {centeredDate ? (
                <Flex alignItems="center" justifyContent="center" width="100%">
                    <ChevronLeftIcon
                        onClick={decreaseMonth}
                        style={{
                            width: '24px',
                            height: '24px',
                            cursor: 'pointer'
                        }}
                    />
                    <Text paddingRight="30px" paddingLeft="30px" margin={0}>
                        {date.toLocaleDateString('en-US', { month: 'long' })} {date.getFullYear()}
                    </Text>
                    <ChevronRightIcon
                        onClick={increaseMonth}
                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                    />
                </Flex>
            ) : (
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                    <Flex alignItems="center">
                        <Text margin={0}>
                            {date.toLocaleDateString('en-US', { month: 'long' })}{' '}
                            {date.getFullYear()}
                        </Text>
                    </Flex>
                    <Flex alignItems="end">
                        <ChevronLeftIcon
                            onClick={decreaseMonth}
                            style={{
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                                paddingRight: '8px'
                            }}
                        />
                        <ChevronRightIcon
                            onClick={increaseMonth}
                            style={{
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                                paddingLeft: '8px'
                            }}
                        />
                    </Flex>
                </Flex>
            )}
        </Box>
    );
};

export default DatePickerHeaderControls;
