import React, { memo, useState } from 'react';
import { Flex, Heading, Text, useTheme } from '@chakra-ui/react';
import { useClientApiFetch } from 'ts/client/common';
import { Button } from 'ts/common/components/gallery';
import { FetchMethod, useBreakpoint, useTranslate } from 'ts/common/hooks';

interface IActionBox {
    decodedToken: SpApi.Client.IDecodedUnsubscribeToken;
    isInitiallySubscribed: boolean;
}

const ActionBox = ({ decodedToken, isInitiallySubscribed }: IActionBox) => {
    const { brandId, email, eventId } = decodedToken;

    const t = useTranslate('unsubscribePage');
    const theme = useTheme();
    const isLargeScreen = useBreakpoint();

    const [userSelectedState, setUserSelectedState] =
        useState<Nullable<SpApi.Client.SubscriptionState>>(null);

    const { performFetch: updateSubscription } = useClientApiFetch<SpApi.Client.ISubscription>(
        `brand/${brandId}/event/${eventId}/subscription`,
        {
            defer: true,
            method: FetchMethod.POST
        }
    );

    const updateSubscribeState = (state: SpApi.Client.SubscriptionState) => {
        updateSubscription({ data: { email, state } });
        setUserSelectedState(state);
    };

    const getHeaderText = () => {
        if (userSelectedState === null) {
            if (isInitiallySubscribed) {
                return t('unsubscribeHeader');
            }

            return t('resubscribeHeader');
        } else if (userSelectedState === 'subscribed') {
            return t('subscribedHeader');
        }

        return t('unsubscribedHeader');
    };

    const getDescriptionText = () => {
        if (userSelectedState === null) {
            if (isInitiallySubscribed) {
                return t('areYouSureDescription');
            }

            return `${email} ${t('resubscribeDescription')}`;
        } else if (userSelectedState === 'subscribed') {
            return t('subscribedDescription');
        }

        return `${t('unsubscribedDescriptionOne')} ${email} ${t('unsubscribedDescriptionTwo')}`;
    };

    const getActionButtons = () => {
        return (
            <>
                {userSelectedState === 'subscribed' ? (
                    <Button
                        onClick={() => {
                            window.location.replace(
                                new URL(`/gallery/${eventId}/`, window.location.origin).toString()
                            );
                        }}
                        text={t('viewGalleryAction')}
                        width="50%"
                    />
                ) : (
                    <Button
                        onClick={() => {
                            updateSubscribeState('subscribed');
                        }}
                        text={
                            userSelectedState === null && isInitiallySubscribed
                                ? t('keepSubscribedAction')
                                : t('subscribeAction')
                        }
                        width="50%"
                    />
                )}

                {userSelectedState === null && isInitiallySubscribed && (
                    <Button
                        onClick={() => {
                            updateSubscribeState('unsubscribed');
                        }}
                        text={t('unsubscribeAction')}
                        variant="outline"
                        width="50%"
                    />
                )}
            </>
        );
    };

    return (
        <Flex
            flexDirection="column"
            position="relative"
            background="paper"
            borderRadius="20px"
            boxShadow={isLargeScreen ? '0px 4px 40px 0px rgba(0, 0, 0, 0.08)' : 'none'}
            fontFamily={theme.fonts}
            marginX="auto"
            boxSizing="border-box"
            gap="30px"
            height="268px"
            maxWidth="960px"
            padding="40px 34px"
            marginTop="300px"
        >
            <Heading as="h1" color="brand.primary" margin="0px" size="3xl">
                {getHeaderText()}
            </Heading>
            <Text>{getDescriptionText()}</Text>
            <Flex
                justifyContent="center"
                {...(!isLargeScreen
                    ? {
                          background: 'paper',
                          filter: theme.filters.dropShadow,
                          gap: '12px',
                          padding: '16px',
                          position: 'fixed',
                          bottom: 0,
                          left: 0,
                          right: 0
                      }
                    : { gap: '48px' })}
            >
                {getActionButtons()}
            </Flex>
        </Flex>
    );
};

export default memo(ActionBox);
