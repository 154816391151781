import React, { useLayoutEffect, useState } from 'react';
import { Box, styled } from 'sp-ui';
import { scrollTo } from 'Sp/SmoothScroll';
import { ReactComponent as IconArrowRight } from 'svg/client/brand/icon-arrow-right.svg';

const BackToTopContainer = styled(Box)(
    ({ theme }) => `
        position: fixed;
        top: 10%;
        right: 18px;
        height: 56px;
        width: 56px;
        border-radius: 28px;
        text-align: center;
        cursor: pointer;
        opacity: 0;
        pointer-events: none;
        background-color: ${theme.brand.colors.theme.secondary.light};
        box-shadow: ${theme.brand.colors.boxShadow};
        transition: opacity 0.3s ease-in-out;

        &:hover {
            box-shadow: ${theme.brand.colors.boxShadowOnHover};
        }

        &.back-to-top-active {
            opacity: 1;
            pointer-events: auto;
        }
    `
);
const IconArrowUp = styled(IconArrowRight)(
    ({ theme }) => `
        margin: 20px auto;
        width: 20px;
        height: 14px;
        fill: ${theme.brand.colors.user.primary};
        transform: rotate(-90deg);
    `
);

const isWindowScrollingUp = (scrollY: number, lastWindowScrollPosition: number) => {
    return scrollY < lastWindowScrollPosition;
};

const isWindowVerticallyScrolledEnough = (scrollY: number, innerHeight: number) => {
    return scrollY > innerHeight;
};

const scrollWindowToTop = () => {
    scrollTo(0);
};

const BackToTop: React.FC = () => {
    const [showBackToTop, setShowBackToTop] = useState(false);

    useLayoutEffect(() => {
        let lastWindowScrollPosition: number = window.pageYOffset;

        const handleScroll = ({ target }) => {
            const { clientHeight: height, scrollTop } = target.scrollingElement;

            if (
                isWindowVerticallyScrolledEnough(scrollTop, height) &&
                isWindowScrollingUp(scrollTop, lastWindowScrollPosition)
            ) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }

            lastWindowScrollPosition = scrollTop;
        };

        window.addEventListener('scroll', handleScroll);

        () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <BackToTopContainer
            data-testid="back-to-top-container"
            className={showBackToTop ? 'back-to-top-active' : ''}
            id="back-to-top-container"
            onClick={scrollWindowToTop}
        >
            <IconArrowUp data-testid="back-to-top-icon-arrow" />
        </BackToTopContainer>
    );
};

export default BackToTop;
