import React, { FC } from 'react';
import RadioGroupGrid from 'ts/common/components/RadioGroupGrid';
import { useTranslate } from 'ts/common/hooks';
import { convertTimeToMinutes } from '../helpers';

interface ITimePickerProps {
    timeslots: string[];
    selectedTimeslot?: string;
    onTimeslotChange: (timeslot: string) => void;
}

interface TimeOption {
    name: string;
    value: number;
}

const TimePicker: FC<ITimePickerProps> = ({ selectedTimeslot, timeslots, onTimeslotChange }) => {
    const t = useTranslate('booking.dateAndTimePage');

    const splitTimeslots = (timeslots: string[]) => {
        const AMOptions: TimeOption[] = [];
        const PMOptions: TimeOption[] = [];
        const MilitaryOptions: TimeOption[] = [];

        timeslots.forEach((slot) => {
            const time = new Date(slot);
            const name = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const value = convertTimeToMinutes(time);

            if (name.toLowerCase().includes('am')) {
                AMOptions.push({ name, value });
            } else if (name.toLowerCase().includes('pm')) {
                PMOptions.push({ name, value });
            } else {
                MilitaryOptions.push({ name, value });
            }
        });

        return { AMOptions, PMOptions, MilitaryOptions };
    };

    const { AMOptions, PMOptions, MilitaryOptions } = splitTimeslots(timeslots);

    /*
        This function calls the callback function onTimeslotChange with the
        selectedSlot selection which at this time should be a number
        from the parent component. We take that number and do a compare to each
        of the string timeslots that are converted to minutes. Upon a match
        we return that string timeslot.
    */
    const handleSelection = (value: number) => {
        const selectedSlot =
            timeslots &&
            timeslots.find((slot) => {
                const time = new Date(slot);
                const slotValue = convertTimeToMinutes(time);
                return slotValue === value;
            });

        // We do not have a default selection, so we want to protect against this value being null
        if (selectedSlot) {
            onTimeslotChange(selectedSlot); // Pass the selected timeslot string back to the parent
        }
    };

    const selectTime = selectedTimeslot ? new Date(selectedTimeslot) : undefined;
    const selectedTimeValue = selectTime ? convertTimeToMinutes(selectTime) : undefined;
    return (
        <>
            {AMOptions.length > 0 && (
                <RadioGroupGrid
                    columnOverride={3}
                    isClientBooking
                    label="AM"
                    fieldName="AMTimes"
                    onChange={(_, value) => handleSelection(value)}
                    options={AMOptions}
                    parentFormValue={selectedTimeValue}
                />
            )}

            {PMOptions.length > 0 && (
                <RadioGroupGrid
                    columnOverride={3}
                    isClientBooking
                    label="PM"
                    fieldName="PMTimes"
                    onChange={(_, value) => handleSelection(value)}
                    options={PMOptions}
                    parentFormValue={selectedTimeValue}
                />
            )}

            {MilitaryOptions.length > 0 && (
                <RadioGroupGrid
                    columnOverride={3}
                    isClientBooking
                    label={t('timeSelect')}
                    fieldName="MilitaryTimes"
                    onChange={(_, value) => handleSelection(value)}
                    options={MilitaryOptions}
                    parentFormValue={selectedTimeValue}
                />
            )}
        </>
    );
};

export default TimePicker;
