import React from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary, ThemeProvider, styled, withI18NextTranslate } from 'sp-ui';
import api from 'client_react/brand/hooks/api';
import { useBootstrapData, withBootstrapData } from '../bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import { withBrand } from './hooks/BrandContext';
import { withBrandHomepage } from './hooks/BrandHomepageContext';
import { getBrandTheme } from './theme';
import { IBrandHomepage } from './types';

const AppContainer = styled.div(({ theme }) => {
    return `
        background-color: ${theme.brand.colors.theme.primary};
        font-size: 14px;
        height: 100%;
        min-height: 100vh;
        width: 100vw;
        padding-top: 21px;
        display: flex;
        flex-direction: column;

        ${theme.responsive.mediumScreenUp`
            padding-top: 49px;
        `}
    `;
});
const App = withBootstrapData(() => {
    const { translations } = useBootstrapData();
    const AppWithTranslations = withI18NextTranslate(() => {
        return (
            <AppContainer onContextMenu={(e) => e.preventDefault()}>
                <Header />
                <Outlet />
                <Footer />
            </AppContainer>
        );
    }, translations);
    const BrandAppWithData = withBrand(withBrandHomepage(AppWithTranslations));

    const BrandApp = () => {
        const { response: brandResponse } = api.useClientApiFetch<IBrand>();
        const { response: brandHomepageResponse } =
            api.useClientApiFetch<IBrandHomepage>('homepage');

        if (!brandResponse || !brandHomepageResponse) {
            return null;
        }

        const brandTheme = getBrandTheme(brandHomepageResponse.brandTheme);

        return (
            <ThemeProvider resetCSS={true} partialTheme={brandTheme}>
                <BrandAppWithData brand={brandResponse} brandHomepage={brandHomepageResponse} />
            </ThemeProvider>
        );
    };

    return (
        <ErrorBoundary>
            <BrandApp />
        </ErrorBoundary>
    );
});

export default App;
