import React, { useState } from 'react';
import { Button, ButtonVariant, Flex, styled } from 'sp-ui';
import { ReactComponent as CheckIcon } from 'svg/check.svg';

interface IButtonGroupOption {
    content: React.ReactNode;
    value: string;
    Icon?: React.ComponentType | null;
}

interface IButtonGroupProps {
    options: IButtonGroupOption[];
    onClick?: (optionValue: string) => void;
}

const StyledButton = styled(Button)(({ theme }) => {
    const { activeColor, backgroundColor, inactiveColor } = theme.components.ButtonGroup.baseStyle;

    return `
        background-color: ${backgroundColor};
        height: 40px;
        width: 100%;

        &.is-selected {
            border-color: ${activeColor};
            color: ${activeColor};

            + button {
                border-left-color: ${activeColor};
            }

            svg path {
                fill: ${activeColor}
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:first-of-type, &:not(:last-of-type) {
            border-right-width: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &:not(.is-selected) {
            color: ${inactiveColor};
        }

        &:not(:first-of-type) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    `;
});

const ButtonGroup: React.FC<IButtonGroupProps> = ({ onClick, options, ...props }) => {
    const [selectedOptionValue, setSelectedOptionValue] = useState<string>(options[0].value);

    function onClickButton(optionValue: string) {
        if (optionValue === selectedOptionValue) {
            return;
        }

        setSelectedOptionValue(optionValue);

        if (onClick) {
            onClick(optionValue);
        }
    }

    return (
        <Flex width="100%" data-testid="button-group" {...props}>
            {options.map(({ content, Icon = CheckIcon, value }) => {
                const isSelected = value === selectedOptionValue;

                return (
                    <StyledButton
                        className={isSelected ? 'is-selected' : undefined}
                        onClick={() => onClickButton(value)}
                        key={value}
                        type="button"
                        variant={ButtonVariant.Tertiary}
                    >
                        <Flex alignItems="center">
                            {isSelected && Icon && (
                                <Flex marginRight="6px">
                                    <Icon width="12px" />
                                </Flex>
                            )}
                            {content}
                        </Flex>
                    </StyledButton>
                );
            })}
        </Flex>
    );
};

export default ButtonGroup;
