import { useEffect } from 'react';
import { FormikErrors, FormikValues, useFormikContext } from 'sp-ui';

export interface IFormErrorsProps<T extends FormikValues> {
    errors?: FormikErrors<T>;
}

export const FormErrors = <T extends FormikValues>({ errors }: IFormErrorsProps<T>) => {
    const { setErrors } = useFormikContext<T | undefined>();

    useEffect(() => {
        setErrors(errors);
    }, [errors, setErrors]);

    return null;
};
