import React, { FC } from 'react';
import { Flex, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { Heart, Search } from 'react-feather';
import useNavTabs from 'ts/common/hooks/useNavTabs';
import {
    CartEnabledIcon,
    CartIcon,
    ImageEnabledIcon,
    ImageIcon,
    InfoEnabledIcon,
    InfoIcon,
    StoreEnabledIcon,
    StoreIcon
} from '../icons';
import BottomNavLink from './BottomNavLink';
import CountBadge from './CountBadge';
import NavTabs from './NavTabs';

interface Props {
    /** The number of items in the cart */
    cartCount?: number;
    /** The number of favorites */
    favoritesCount?: number;
    /** The href for the Home nav link */
    homeLinkHref: string;
    /** The label to be translated for the Home nav link */
    homeLinkLabel: string;
    /* True only if the About Contact link is shown */
    isAboutContactVisible?: boolean;
    /* True only if the About Message link is shown */
    isAboutMessageVisible?: boolean;
    /* True only if the About Pricing link is shown */
    isAboutPricingVisible?: boolean;
    /** True only if the About nav link is shown */
    isAboutVisible?: boolean;
    /** True only if the component should use absolute positioning, instead of fixed */
    isAbsolutePositioned?: boolean;
    /* True only if admin mode is enabled (which enables hiding and labeling) */
    isAdminModeEnabled?: boolean;
    /** True only if the Cart nav link is shown */
    isCartVisible?: boolean;
    /* True only if hiding photos is allowed */
    isHiddenPhotosVisible?: boolean;
    /** True only if the secondary nav on the Home page should be shown */
    isHomeSecondaryNavVisible?: boolean;
    /* True only if labeling photos is allowed */
    isLabeledPhotosVisible?: boolean;
    /** True only if the package builder is open */
    isPackageBuilderOpen?: boolean;
    /** True only if the Search nav link is shown */
    isSearchVisible?: boolean;
    /** True if the Store should be shown */
    isStoreEnabled?: boolean;
    /** Callback for reloading the current route */
    reloadRoute?: () => unknown;
    /** The identifier of the selected route */
    selectedRoute: 'Home' | 'Album' | 'Special' | 'Search' | 'Info' | 'Cart' | 'Store';
    /** The current path from the URL, excluding the base path of the gallery */
    selectedUri: string;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const BottomNav: FC<Props> = ({
    cartCount = 0,
    favoritesCount = 0,
    homeLinkHref,
    homeLinkLabel,
    isAboutContactVisible,
    isAboutMessageVisible,
    isAboutPricingVisible,
    isAboutVisible,
    isAbsolutePositioned,
    isAdminModeEnabled,
    isCartVisible,
    isHiddenPhotosVisible,
    isHomeSecondaryNavVisible,
    isLabeledPhotosVisible,
    isPackageBuilderOpen,
    isSearchVisible,
    isStoreEnabled,
    reloadRoute,
    selectedRoute,
    selectedUri,
    translateFilter: translate
}) => {
    const isHomeSelected = selectedRoute === 'Home' || selectedRoute === 'Album';
    const isStoreSelected = selectedRoute === 'Store';
    const isFavoritesSelected = selectedRoute === 'Special';
    const isSearchSelected = selectedRoute === 'Search';
    const isAboutSelected = selectedRoute === 'Info';
    const isCartSelected = selectedRoute === 'Cart';

    const { navTabs, navTabsKey, selectedNavTabIndex } = useNavTabs({
        isAboutContactVisible,
        isAboutMessageVisible,
        isAboutPricingVisible,
        isAdminModeEnabled,
        isHiddenPhotosVisible,
        isHomeSecondaryNavVisible,
        isLabeledPhotosVisible,
        selectedRoute,
        selectedUri,
        translate
    });

    const theme = useTheme();

    return (
        <Flex
            bottom={isPackageBuilderOpen ? '92px' : 0}
            direction="column"
            gap="8px"
            position={isAbsolutePositioned ? 'absolute' : 'fixed'}
            width="full"
            zIndex={1}
        >
            {navTabs && (
                <Flex
                    alignItems="center"
                    background={theme.isLightColorScheme ? 'background' : 'gray.600'}
                    borderRadius="2px"
                    filter={theme.filters?.dropShadow}
                    flexDirection="row"
                    justifyContent="center"
                    marginX="16px"
                    opacity={0.98}
                    padding="6px"
                >
                    <NavTabs
                        key={navTabsKey}
                        selectedTabIndex={selectedNavTabIndex}
                        tabs={navTabs}
                    />
                </Flex>
            )}
            <Flex as="nav" backgroundColor="background" flexFlow="row nowrap" paddingX="16px">
                <BottomNavLink href={homeLinkHref} isSelected={isHomeSelected}>
                    {isHomeSelected ? <ImageEnabledIcon /> : <ImageIcon />}
                    {translate(homeLinkLabel)}
                </BottomNavLink>
                {isCartVisible && isStoreEnabled && (
                    <BottomNavLink
                        href={'store'}
                        isSelected={isStoreSelected}
                        onClick={reloadRoute}
                    >
                        {isStoreSelected ? <StoreEnabledIcon /> : <StoreIcon />}
                        {translate('store')}
                    </BottomNavLink>
                )}
                <BottomNavLink
                    href="favorites"
                    isSelected={isFavoritesSelected}
                    aria-label={translate('favorites')}
                    data-testid="viewFavoritesLink"
                >
                    <CountBadge count={favoritesCount} data-testid="favoritesCount">
                        <Heart
                            display="block"
                            fill={isFavoritesSelected ? 'currentColor' : 'none'}
                        />
                    </CountBadge>
                    {translate('favorites')}
                </BottomNavLink>
                {isSearchVisible && (
                    <BottomNavLink href="search" isSelected={isSearchSelected}>
                        <Search fill={isSearchSelected ? 'currentColor' : 'none'} />
                        {translate('search')}
                    </BottomNavLink>
                )}
                {isAboutVisible && (
                    <BottomNavLink href="info" isSelected={isAboutSelected}>
                        {isAboutSelected ? <InfoEnabledIcon /> : <InfoIcon />}
                        {translate('about')}
                    </BottomNavLink>
                )}
                {isCartVisible && (
                    <BottomNavLink
                        href="cart"
                        isSelected={isCartSelected}
                        aria-label={translate('cart')}
                    >
                        <CountBadge count={cartCount}>
                            {isCartSelected ? (
                                <CartEnabledIcon display="block" />
                            ) : (
                                <CartIcon display="block" />
                            )}
                        </CountBadge>
                        {translate('cart')}
                    </BottomNavLink>
                )}
            </Flex>
        </Flex>
    );
};

export default BottomNav;

export const BottomNavSmallOnly: FC<Props> = (props) =>
    useBreakpointValue({
        base: <BottomNav {...props} />,
        lg: <></>
    }) ?? null;
