import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Box, styled } from 'sp-ui';
import { CoverPhotoImageSize } from 'ts/common';
import { computeFocalPointImageValues } from 'ts/common/utils';
import { CoverPhoto } from './CoverPhoto';
import { Link } from './Link';

interface IBrandHomepageTileProps {
    coverPhoto: Nullable<SpApi.ICoverPhoto>;
    coverPhotoImageSize?: CoverPhotoImageSize;
    linkText: string;
    linkUrl: string;
    useExternalLink?: boolean;
}

const TileContainer = styled(Box)`
    @media only screen and (min-width: 1280px) {
        width: 360px;
        margin: 0 30px 40px;
    }

    text-align: center;
    width: 280px;
    margin: 0 16px 38px;
    overflow-wrap: break-word;
`;

const BrandHomepageTile: React.FC<IBrandHomepageTileProps> = ({
    coverPhoto,
    coverPhotoImageSize = CoverPhotoImageSize.Thumb,
    linkText,
    linkUrl,
    useExternalLink = false
}) => {
    const [focalPointValues, setFocalPointValues] = useState({
        height: '100%',
        width: 'auto',
        offsetX: '0px',
        offsetY: '0px'
    });
    const handleResize = useCallback(() => {
        if (imageElement.current) {
            if (coverPhoto) {
                const { height, width, offsetX, offsetY } = computeFocalPointImageValues(
                    imageElement.current.offsetWidth,
                    imageElement.current.offsetHeight,
                    coverPhoto.width,
                    coverPhoto.height,
                    coverPhoto.focalPointPercentages
                );

                setFocalPointValues({
                    height: `${height}px`,
                    width: `${width}px`,
                    offsetX: `${offsetX}px`,
                    offsetY: `${offsetY}px`
                });
            }
        }
    }, [coverPhoto]);
    let imageUrl: Nullable<string> = null;
    const imageElement = useRef<HTMLDivElement>(null);

    if (coverPhoto) {
        const selectedDisplayUrl = coverPhoto.displayUrl[coverPhotoImageSize] || null;

        if (selectedDisplayUrl) {
            imageUrl = selectedDisplayUrl;
        }
    }

    useLayoutEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <TileContainer data-testid="brand-homepage-tile-box">
            <Link isExternal={useExternalLink} url={linkUrl}>
                <CoverPhoto
                    ref={imageElement}
                    imageUrl={imageUrl}
                    focalPointValues={focalPointValues}
                />
                <span data-testid="brand-homepage-tile-link-text">{linkText}</span>
            </Link>
        </TileContainer>
    );
};

export default BrandHomepageTile;
